.sb-button {
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  gap: 0.625rem;
  font-weight: 600;
  line-height: 1.2em;
  padding: 0.9rem 1.25rem;
}
.sb-button--color-primary {
  background: #007aff;
  border: none;
  color: white;
  position: relative;
}
.sb-button--color-primary::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.sb-button--color-primary::after {
  background-color: white;
}
.sb-button--color-primary:active, .sb-button--color-primary:focus, .sb-button--color-primary:hover {
  background: #007aff;
  color: white;
}
.sb-button--color-primary:active::after, .sb-button--color-primary:focus::after, .sb-button--color-primary:hover::after {
  opacity: 0.08;
}
.sb-button--color-primary[disabled] {
  background: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.sb-button--color-secondary {
  background: #e6e6e6;
  border: none;
  color: #333333;
  position: relative;
}
.sb-button--color-secondary::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.sb-button--color-secondary::after {
  background-color: #333333;
}
.sb-button--color-secondary:active, .sb-button--color-secondary:focus, .sb-button--color-secondary:hover {
  background: #e6e6e6;
  border: none;
  color: #333333;
}
.sb-button--color-secondary:active::after, .sb-button--color-secondary:focus::after, .sb-button--color-secondary:hover::after {
  opacity: 0.08;
}
.sb-button--color-secondary[disabled] {
  background: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.sb-button--color-danger {
  background: #d70015;
  border: none;
  color: white;
}
.sb-button--color-danger:hover {
  background: #d70015;
  color: white;
}
.sb-button--color-danger[disabled] {
  background-color: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
}
.sb-button--size-slim {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}
.sb-button--variant-icon {
  padding: 0.9rem;
}
.sb-button--variant-icon.sb-button--color-secondary {
  background: transparent;
}
.sb-button--variant-icon.sb-button--color-secondary::after {
  display: none;
}
.sb-button--variant-icon.sb-button--color-primary {
  background: #efefef;
  color: #333333;
}
.sb-button--variant-icon.sb-button--color-primary.sb-button--size-slim {
  padding: 0.375rem;
}
.sb-button--variant-icon.sb-button--color-primary::after {
  display: none;
}
.sb-button--variant-icon.sb-button--color-primary:hover {
  background: #e6e6e6;
  color: #1f1f1f;
}
.sb-button--variant-text {
  background: transparent;
  position: relative;
}
.sb-button--variant-text::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.sb-button--variant-text::after {
  background-color: #333333;
}
.sb-button--variant-text:active, .sb-button--variant-text:focus, .sb-button--variant-text:hover {
  background: transparent;
  border: none;
  color: #333333;
}
.sb-button--variant-text:active::after, .sb-button--variant-text:focus::after, .sb-button--variant-text:hover::after {
  opacity: 0.08;
}
.sb-button--variant-text[disabled] {
  background: transparent;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.sb-button--variant-text.sb-button--color-danger {
  color: #dc3545;
}
.sb-button--variant-link {
  box-shadow: none;
  background: transparent;
  font-weight: 400;
  line-height: 1;
  padding: 0;
}
.sb-button--variant-link.sb-button--color-primary {
  color: #007aff;
}
.sb-button--variant-link.sb-button--color-danger {
  color: #dc3545;
}
.sb-button--variant-link:after {
  display: none;
}
.sb-button--variant-link:active, .sb-button--variant-link:focus, .sb-button--variant-link:hover {
  box-shadow: none;
  background: transparent;
  color: black;
}
.sb-button--variant-link[disabled] {
  background: transparent;
  color: rgba(51, 51, 51, 0.38);
}
.sb-button--variant-outline {
  background-color: white;
  border: 1px solid #ced4da;
}
.sb-button--variant-outline:active, .sb-button--variant-outline:focus, .sb-button--variant-outline:hover {
  border: 1px solid #ced4da;
}
.sb-button--variant-outline:active:after, .sb-button--variant-outline:focus:after, .sb-button--variant-outline:hover:after {
  background-color: rgba(51, 51, 51, 0.08);
}
.sb-button--variant-outline[disabled] {
  background: white;
  color: rgba(51, 51, 51, 0.38);
}
.sb-button:hover {
  cursor: pointer;
}

.dark .sb-button--color-primary[disabled] {
  background: #232323;
  color: rgba(204, 204, 204, 0.38);
}
.dark .sb-button--color-secondary {
  background-color: #232323;
  color: #cccccc;
}
.dark .sb-button--color-secondary::after {
  background-color: white;
}
.dark .sb-button--color-secondary[disabled] {
  background: #232323;
  color: rgba(204, 204, 204, 0.38);
}
.dark .sb-button--color-danger {
  background: #ff6961;
  color: white;
}
.dark .sb-button--color-danger:hover {
  background: #ff6961;
}
.dark .sb-button--color-danger[disabled] {
  background-color: #333333;
  color: rgba(204, 204, 204, 0.38);
}
.dark .sb-button--variant-text {
  background: transparent;
  color: #cccccc;
}
.dark .sb-button--variant-text::after {
  background-color: #cccccc;
}
.dark .sb-button--variant-text[disabled] {
  background: transparent;
  color: rgba(204, 204, 204, 0.38);
}
.dark .sb-button--variant-text.sb-button--color-danger {
  color: #dc3545;
}
.dark .sb-button--variant-link {
  background: transparent;
}
.dark .sb-button--variant-link.sb-button--color-danger {
  color: #dc3545;
}
.dark .sb-button--variant-link.sb-button--color-danger:hover {
  background: none;
  color: black;
}
.dark .sb-button--variant-link:not(.sb-button--color-danger):hover {
  color: white;
}
.dark .sb-button--variant-link[disabled] {
  background: transparent;
  color: rgba(204, 204, 204, 0.38);
}
.dark .sb-button--variant-icon.sb-button--color-primary {
  background: #313131;
  color: #cccccc;
}
.dark .sb-button--variant-icon.sb-button--color-primary:hover {
  background: #2e2e2e;
  color: #dadada;
}
