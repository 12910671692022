.sb-text {
  margin: 0;
  padding: 0;
}
.sb-text--secondary {
  color: rgba(51, 51, 51, 0.64);
}

.dark .sb-text--secondary {
  color: rgba(204, 204, 204, 0.64);
}
