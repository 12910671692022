.privacy-and-settings__restrictions__text-field-container__change-password-btn {
  background-color: white;
  padding: 0.526rem 0.75rem;
}
.privacy-and-settings__restrictions__text-field-container__password {
  gap: 0.75rem;
}
.privacy-and-settings__restrictions__text-field-container__password .sb-text-field {
  flex-grow: 1;
}
.privacy-and-settings__restrictions__text-field-container__password .sb-text-field .sb-input {
  padding: 0.313rem 0.5rem;
}
.privacy-and-settings__restrictions__text-field-container__password .sb-button {
  padding: 0.526rem 0.75rem;
}
