.report {
  max-width: 620px;
  margin: 30px auto !important;
  padding: 20px;
}
.report h3 {
  margin-bottom: 25px;
}
.report input[type=radio] {
  margin-right: 10px;
}
.report input[type=checkbox] {
  margin-right: 10px;
  float: left;
  margin-top: 5px;
}
