.dark .draggable-video-item-stack__card {
  background: black;
  border: solid 0.3125rem #748490;
}

.draggable-video-item-stack {
  position: relative;
  height: 8.15rem;
  width: 14.5rem;
  cursor: grabbing;
}
.draggable-video-item-stack__card {
  background: white;
  border: solid 0.3125rem white;
  border-radius: 0.625rem;
  filter: drop-shadow(0px 0.25rem 1.25rem rgba(36, 36, 87, 0.2));
  height: 100%;
  width: 100%;
  margin: 0px;
  overflow: hidden;
  position: absolute;
  z-index: 3;
}
.draggable-video-item-stack__card .badge {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 0.188rem;
  line-height: normal;
  top: 0.25rem;
  right: 0.25rem;
  position: absolute;
  width: fit-content;
}
.draggable-video-item-stack__card--stacked-0 {
  transform: translate(0.188rem, 0.188rem);
  z-index: 2;
}
.draggable-video-item-stack__card--stacked-1 {
  transform: translate(0.396rem, 0.396rem);
  z-index: 1;
}
