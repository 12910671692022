.dark .sb-modal__backdrop {
  opacity: 0.5;
}
.dark .sb-modal__panel {
  background-color: #232323;
  color: #cccccc;
}
.dark .sb-modal .sb-button--secondary {
  background-color: #232323;
  color: #cccccc;
}

.sb-modal {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1100;
}
.sb-modal__backdrop {
  background-color: black;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
}
.sb-modal__backdrop--hidden {
  background: transparent;
}
.sb-modal__container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.sb-modal__panel {
  background-color: white;
  border-radius: 0.625rem;
  color: #232323;
  max-width: 31.25rem;
  width: 100%;
  z-index: 1;
}
.sb-modal__panel__header {
  padding: 1.5rem;
}
.sb-modal__panel__header__title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  margin: 0;
}
.sb-modal__panel__header__close-button {
  border-radius: none;
  padding: 0 0.25rem;
}
.sb-modal__panel__header__close-button:hover {
  background: transparent;
}
.sb-modal__panel__content {
  font-size: 0.875rem;
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
  padding: 0.625rem 1.5rem;
}
.sb-modal__panel__footer {
  gap: 0.625rem;
  padding: 1.25rem 1.5rem;
}
.sb-modal__panel__footer .sb-button {
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem;
}
