.dark .videos-list-header .video-list-actions {
  color: #cccccc;
}
.dark .videos-list-header .video-list-actions .selection-indicator {
  color: white;
}
.dark .videos-list-header .video-list-actions .video-list-action {
  color: #cccccc;
}
.dark .videos-list-header .video-list-actions .video-list-action:hover {
  color: white;
}
.dark .videos-list-header .video-list-actions .video-list-action--disabled {
  color: #6b6d70;
}

.videos-list-header {
  padding: 0.625rem;
  width: 100%;
}
.videos-list-header .alert {
  border-radius: 0.625rem;
}
.videos-list-header .alert a {
  color: inherit;
  font-weight: 600;
}
.videos-list-header .alert a:hover {
  color: black;
}
.videos-list-header .alert span {
  font-weight: 600;
  cursor: pointer;
}
.videos-list-header .alert span:hover {
  color: black;
}
.videos-list-header .video-list-actions {
  align-items: center;
  border-radius: 0.625rem;
  color: #748490;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  gap: 0.75rem;
  line-height: 1.75rem;
  padding: 0 0.75rem;
  height: 3.875rem;
}
.videos-list-header .video-list-actions button {
  border: none;
  background: none;
  box-shadow: none;
  color: #545454;
}
.videos-list-header .video-list-actions__merge, .videos-list-header .video-list-actions__delete {
  display: none;
}
@media (min-width: 36rem) {
  .videos-list-header .video-list-actions__merge, .videos-list-header .video-list-actions__delete {
    display: block;
  }
  .videos-list-header .video-list-actions__overflow {
    display: none;
  }
  .videos-list-header .video-list-actions > div .video-list-action--btn--share__text {
    display: inline;
  }
}
.videos-list-header .video-list-actions .video-list-action {
  align-items: center;
  color: #515151;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  gap: 0.5rem;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.videos-list-header .video-list-actions .video-list-action:hover {
  color: black;
}
.videos-list-header .video-list-actions .video-list-action--disabled {
  color: #b1b1b1;
  pointer-events: none;
}
.videos-list-header .video-list-actions .video-list-action span {
  font-weight: 400;
}
.videos-list-header .video-list-actions .video-list-action--btn {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.videos-list-header .video-list-actions .video-list-action--btn::after {
  display: none;
}
.videos-list-header .video-list-actions .video-list-action--btn--share {
  padding-right: 0;
}
.videos-list-header .video-list-actions .video-list-action--btn--share__text {
  padding-left: 0.5rem;
  display: none;
}
.videos-list-header .video-list-actions a,
.videos-list-header .video-list-actions .item {
  padding: 0.75rem;
}
.videos-list-header .video-list-actions a svg,
.videos-list-header .video-list-actions .item svg {
  position: relative;
}
.videos-list-header .video-list-actions .close-action {
  border: none;
  min-width: fit-content;
  padding: 0;
}
.videos-list-header .video-list-actions .close-action svg {
  margin-right: 0;
  position: relative;
  height: 1.25rem;
  width: 1.25rem;
}
.videos-list-header .video-list-actions .selection-indicator {
  height: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #232323;
  font-size: 1rem;
  vertical-align: inherit;
  border: none;
  position: relative;
  border-radius: 0.188rem;
  min-width: fit-content;
  margin-right: auto;
}
.videos-list-header .video-list-actions .video-list-selection {
  position: relative;
  left: -0.25rem;
}
@media (min-width: 62rem) {
  .videos-list-header {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
  }
}
.videos-list-header .desktop,
.videos-list-header .tablet {
  display: none;
}
@media (min-width: 36rem) {
  .videos-list-header .tablet {
    display: inline;
  }
  .videos-list-header .videos-list-items {
    flex: 1;
    flex-grow: 0;
  }
}
@media (min-width: 62rem) {
  .videos-list-header .desktop {
    display: inline;
  }
}
