@import '../../../common/scss/variables.scss';

.hero {
  background: linear-gradient(180deg, #fff 0%, transparent 70%),
    linear-gradient(90deg, #007aff 13.4%, #9442ff 86.6%);
  overflow: hidden;
  padding: 3.125rem 0;

  .widget-container {
    max-width: 62.5rem;
    min-width: 32.5rem;
    width: 60%;
    margin-top: 3.125rem;
    position: relative;
    margin: 0 auto;

    .video-example {
      position: absolute;

      &.left-example {
        top: calc(50% - 190px);
        left: -18.75rem;
      }

      &.right-example {
        top: calc(50% - 160px);
        right: -20.75rem;
      }
    }

    .curve {
      position: absolute;

      &.left-curve {
        top: -5rem;
        left: -27rem;
      }

      &.right-curve {
        bottom: -10rem;
        right: -31.25rem;
      }

      &.mobile-curve {
        display: none;
      }
    }
  }

  .explanation-note {
    font-size: 0.875rem;
    color: white;
    max-width: 62.5rem;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    padding: 1.25rem 1.25rem 0;
    z-index: 1;

    &__link {
      color: white;
      text-decoration: underline;
    }
  }

  @media (max-width: $breaking-point-md) {
    border-radius: 0;
    margin: 0;
    height: auto;
    padding-bottom: 0;
    background: linear-gradient(180deg, #fff 0%, transparent 30%),
      linear-gradient(90deg, #007aff 13.4%, #9442ff 86.6%);

    .left-example,
    .left-curve,
    .right-curve {
      display: none;
    }

    .widget-container {
      .curve.mobile-curve {
        display: block;
        top: -3rem;
      }
    }

    .widget-container {
      width: 100%;
      text-align: center;
      height: unset;
      min-width: 0;
      display: initial;

      .widget {
        height: revert;
        position: initial;
        border-radius: 0;
        border: 0;
      }

      .right-example {
        height: 12rem;
        position: initial;
        transform: scale(1.25) rotate(-8deg) translate(0.75rem, -0.625rem);
      }
    }

    .explanation-note {
      width: 100%;
      background: white;
      color: black;
      padding-top: 2rem;

      &__link {
        color: black;
      }
    }
  }
}
