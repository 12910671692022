.testimonials__content {
  padding: 2.5rem 1.25rem;
}
.testimonials__title {
  letter-spacing: -0.125rem;
  line-height: 1em;
  margin-bottom: 2.5rem;
  text-align: center;
}
.testimonials__container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 5rem;
}
@media (min-width: 48rem) {
  .testimonials__content {
    padding: 6.25rem 2.5rem;
    margin-top: 2rem;
  }
  .testimonials__title {
    margin: 0 auto 3.75rem;
    max-width: 50rem;
  }
  .testimonials__container {
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .testimonials__container > * {
    flex-basis: 45%;
    flex-grow: 1;
  }
}
@media (min-width: 75rem) {
  .testimonials__title {
    letter-spacing: -0.03rem;
    line-height: 1.3em;
  }
  .testimonials__content {
    margin: 0 auto;
    max-width: 78rem;
    padding: 6.25rem;
  }
}
