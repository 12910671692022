.dark .videos-list .filter-input,
.dark .videos-list .sort-control,
.dark .videos-list .tag-control {
  color: white !important;
}
.dark .videos-list .filter-input svg,
.dark .videos-list .filter-input select,
.dark .videos-list .sort-control svg,
.dark .videos-list .sort-control select,
.dark .videos-list .tag-control svg,
.dark .videos-list .tag-control select {
  color: white !important;
}
.dark .videos-list .search-text b {
  color: white !important;
}
.dark .videos-list__title {
  color: white;
}
.dark .videos-list .page-item .page-link:hover {
  color: white;
}
.dark .videos-list .page-item.active .page-link {
  color: white !important;
}
.dark .videos-list .loading-indicator {
  filter: grayscale(100%) brightness(1000%);
}
.dark .videos-list .video-list-placeholder {
  color: #aaa;
}
.dark .videos-list .video-list-placeholder .embed-container {
  background-color: #232323;
  border-color: #444;
}
.dark .videos-list .video-list-placeholder .embed-container .placeholder-emphasis {
  color: #aaa;
}
.dark .videos-list .video-list-placeholder .embed-container .placeholder__header {
  color: #fff;
}
.dark .videos-list .video-list-placeholder .embed-container .placeholder__subtitle {
  color: #909090;
}
.dark .videos-list .video-list-placeholder .embed-container:hover {
  background-color: #333;
}

.videos-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.videos-list--full-height {
  flex: 1 1 auto;
}
.videos-list__title {
  color: #333333;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  margin: 0.5rem 0;
  padding: 0 0.5rem 0 0.938rem;
}
.videos-list__title-container {
  gap: 0.25rem;
}
.videos-list__title-count {
  color: #6b6d70;
}
.videos-list__context-menu-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videos-list .row {
  margin: 0px;
  flex: 1 1 auto;
}
.videos-list .videos-list-items {
  padding: 0px;
  overflow: visible;
}
.videos-list .videos-list-items.list {
  margin: 10px;
}
.videos-list nav {
  padding: 20px 0px 10px 10px;
}
.videos-list .page-item {
  cursor: pointer;
}
.videos-list .page-item .page-link {
  font-size: 14px;
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: #838ea2;
}
.videos-list .page-item .page-link:hover {
  background-color: transparent;
  border: none;
  color: black;
}
.videos-list .page-item .page-link.disabled {
  pointer-events: none;
  color: #ddd;
}
.videos-list .page-item.active .page-link {
  background-color: transparent;
  border: none;
  color: black;
  font-weight: 600;
}
.videos-list .page-item.active .page-link:hover {
  background-color: transparent;
  border: none;
  color: black;
  font-weight: 600;
}
.videos-list button {
  font-weight: 600;
  border-radius: 3px;
  height: 26px;
  line-height: 24px;
  font-size: 13px;
}
.videos-list .networkerror {
  display: inline-block;
  padding: 2px 8px;
  background: red;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
}
.videos-list .video-list-placeholder {
  margin: 0px auto;
  padding: 10px;
  width: 100%;
  text-align: center;
}
.videos-list .video-list-placeholder a {
  color: #38566f;
  font-weight: 600;
}
.videos-list .video-list-placeholder a:hover {
  color: black;
}
.videos-list .video-list-placeholder .embed-container {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 50px 0px 50px 0px;
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
}
.videos-list .video-list-placeholder .embed-container .placeholder-emphasis {
  color: black;
}
.videos-list .video-list-placeholder .embed-container:hover {
  background-color: #f9f9f9;
}
.videos-list .video-list-placeholder .embed-container:hover .placeholder__upload-icon {
  background-color: #3e566d;
}
.videos-list .video-list-placeholder .embed-container .placeholder__upload-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #77848f;
  border-radius: 6.25rem;
  font-size: 3rem;
  height: 6rem;
  width: 6rem;
  margin-bottom: 0.625rem;
}
.videos-list .video-list-placeholder .embed-container .placeholder__upload-icon svg {
  height: 40px;
  fill: #fff;
}
.videos-list .video-list-placeholder .embed-container .placeholder__header {
  margin-bottom: 0.5rem;
  color: black;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.videos-list .video-list-placeholder .embed-container .placeholder__subheader {
  padding: 0;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #868e96;
}
.videos-list .video-list-placeholder b {
  display: inline-block;
}
.videos-list .loading-indicator {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videos-list .loading-indicator svg {
  font-size: 40px;
  margin-bottom: 10px;
}
.videos-list .loading-indicator img {
  height: 40px;
  opacity: 0.3;
}
.videos-list .tag-header {
  padding: 8px 15px 0px 15px;
  color: #38566f;
  display: inline-block;
  font-size: 20px;
}
.videos-list .desktop {
  display: none;
}
@media (min-width: 720px) {
  .videos-list {
    display: flex;
    flex-direction: column;
  }
  .videos-list .desktop {
    display: inline;
  }
  .videos-list .videos-list-items {
    flex: 1;
    flex-grow: 0;
  }
}
