.sb-dashboard-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  width: 100%;
}
.sb-dashboard-container--empty {
  flex: 1 1 auto;
}
.sb-dashboard-container .sb-dashboard-content {
  flex-direction: column;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  width: 100%;
}
.sb-dashboard-container .sb-dashboard-content--full-height {
  flex: 1 1 auto;
}
@media (min-width: 62rem) {
  .sb-dashboard-container .sb-dashboard-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
  }
}
.sb-dashboard-container .sb-dashboard-content .row [class*=col-] {
  transition: all 0.4s ease-out;
}

.app-container--sidebar-open .sb-dashboard-container {
  overflow: hidden;
  height: calc(100vh - var(--navbar-height) - var(--footer-height));
}
@media (min-width: 62rem) {
  .app-container--sidebar-open .sb-dashboard-container {
    overflow: initial;
    height: inherit;
    flex: 1;
  }
}
