.widget {
  background: white;
  border-radius: 1.5rem;
  padding: 0 1.25rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 48rem) {
  .widget {
    padding: 1.25rem;
  }
}
