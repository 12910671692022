@import './variables.scss';

// Links
@mixin link-primary {
  color: $color-link-primary;
  text-decoration: none;

  &:hover {
    color: $color-link-primary;
    text-decoration: underline;
  }
}

@mixin link-secondary {
  color: $color-link-secondary;
  text-decoration: underline;

  &:hover {
    color: $color-link-primary;
    text-decoration: underline;
  }
}

// Shadows
@mixin shadow-sm {
  box-shadow: $shadow-sm;
}
@mixin shadow-lg {
  box-shadow: $shadow-lg;
}

// Components

@mixin card {
  @include shadow-lg;
  padding: 2rem;
  background: white !important;
  border: 1px solid $color-outline-secondary;
  border-radius: 1rem;
}
@mixin card-slim {
  @include card;
  padding: 1.5rem;
}
