.billing-details-next {
  gap: 1.5rem;
  color: #1c1c1e;
}
.billing-details-next__payment-form {
  gap: 1.5rem;
}
.billing-details-next__section {
  gap: 1rem;
}
.billing-details-next__heading {
  font-size: 1.25rem;
  font-weight: 700;
}
.billing-details-next__heading--centered {
  align-self: center;
}
