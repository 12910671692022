.settings-option {
  padding: 1rem 0;
}
.settings-option--with-bottom-border {
  border-bottom: 1px solid #e6e6e6;
}
.settings-option--clickable {
  cursor: pointer;
}
.settings-option--disabled .with-upgrade__label,
.settings-option--disabled .settings-option__label-container > *:not(.settings-option__label),
.settings-option--disabled .settings-option__label__content {
  opacity: 0.64;
}
.settings-option__label-container {
  flex-grow: 1;
}
.settings-option__label {
  color: #333333;
  font-weight: 600;
}

.dark .settings-option--with-bottom-border {
  border-bottom: 1px solid #444444;
}
.dark .settings-option__label {
  color: #cccccc;
}
