.plan-features-list {
  gap: 0.75rem;
  list-style: none;
  padding: 0;
  margin: 0;
}
.plan-features-list__item {
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  gap: 0.625rem;
}
.plan-features-list__item__icon {
  flex-shrink: 0;
}
