.sb-checkbox__container {
  flex-shrink: 0;
  height: 1.25rem;
  position: relative;
  width: 1.25rem;
}
.sb-checkbox__input {
  height: 100%;
  position: absolute;
  opacity: 0;
  width: 100%;
}
.sb-checkbox__input:hover {
  cursor: pointer;
}
.sb-checkbox__input[disabled] + .sb-checkbox__custom-checkbox {
  opacity: 0.5;
}
.sb-checkbox__custom-checkbox {
  border: 1px solid #232554;
  border-radius: 0.125rem;
  color: white;
  height: 100%;
  transition: all ease-in-out 0.1s;
  width: 100%;
}
.sb-checkbox__custom-checkbox--checked, .sb-checkbox__custom-checkbox--indeterminate {
  background-color: #007aff;
  border-color: #007aff;
}
.sb-checkbox__custom-checkbox svg {
  max-height: 0.75rem;
  max-width: 0.75rem;
}

.dark .sb-checkbox__custom-checkbox {
  border-color: #cccccc;
}
.dark .sb-checkbox__custom-checkbox--checked, .dark .sb-checkbox__custom-checkbox--indeterminate {
  background-color: #007aff;
  border-color: #007aff;
}
