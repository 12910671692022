:root {
  --navbar-height: 3.125rem;
}

.dark .app-nav .user-menu {
  background: #333;
  color: #ddd;
  border-color: #666;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.dark .app-nav .user-menu .user-menu-header {
  color: #eee;
}
.dark .app-nav .user-menu .border-top {
  border-top: 1px solid #555 !important;
}
.dark .app-nav .navbar {
  background-color: rgb(35, 35, 35);
  border-color: #111;
}
.dark .app-nav .navbar .action-btn {
  color: #eee;
}
.dark .app-nav .navbar .action-btn:hover {
  border: 1px solid #666;
  color: white;
}
.dark .app-nav .navbar .logo {
  filter: invert(100%) brightness(500%);
  opacity: 0.9;
}
.dark .app-nav .navbar .logo:hover {
  opacity: 1;
}

.app-nav {
  position: relative;
}
.app-nav .navbar {
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  font-size: 16px;
  display: flex;
  flex: 0 0 auto;
  height: var(--navbar-height);
  padding: 0px 1rem;
  flex-direction: row;
  padding-right: 10px;
  z-index: 1;
}
@media (max-width: 36rem) {
  .app-nav .navbar {
    padding: 0px 0.625rem;
  }
}
.app-nav .navbar form {
  margin: 0.5rem;
  flex: 1;
}
.app-nav .navbar .logo {
  height: 2rem;
  margin: 0.5rem 0;
}
.app-nav .navbar .nav-item {
  line-height: 25px;
  color: #333;
  text-decoration: none !important;
  font-weight: 600;
  border-radius: 3px;
  margin: 0px 6px;
  padding: 11px 2px;
}
.app-nav .navbar .nav-item svg {
  font-size: 14px;
  margin-right: 2px;
  vertical-align: baseline;
  position: relative;
  top: -2px;
}
.app-nav .navbar .nav-item.selected {
  color: #2c2c2c;
  font-weight: 600;
  border-bottom: 2px solid #2c2c2c;
  pointer-events: none;
}
.app-nav .navbar .nav-item:hover {
  color: black;
  text-decoration: none;
}
.app-nav .navbar .upgrade__link {
  align-items: center;
  color: #007aff;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  gap: 0.25rem;
}
.app-nav .navbar .upgrade__link__icon {
  color: #ffe352;
}
@media (max-width: 500px) {
  .app-nav .navbar .upgrade {
    display: none;
  }
  .app-nav .navbar .login {
    display: none;
  }
}
.app-nav .navbar .login {
  font-size: 1rem;
  margin-right: 1rem;
}
.app-nav .navbar .signup {
  background: #0f90fa;
  margin: 0px 5px;
  padding: 2px 14px;
  color: white;
  border: 1px solid rgba(15, 144, 250, 0.4901960784);
  font-size: 1rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.app-nav .navbar .signup:hover {
  color: white;
  background: #1a70b9;
  box-shadow: none;
}
.app-nav .navbar .spacer {
  margin-left: auto;
}
.app-nav .navbar .action-btn {
  padding: 3px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  position: relative;
  padding-right: 28px;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 36rem) {
  .app-nav .navbar .action-btn {
    padding: 3px 0px;
  }
}
.app-nav .navbar .action-btn svg {
  font-size: 20px;
  position: absolute;
  margin-left: 3px;
  top: 5px;
  right: 7px;
}
.app-nav .navbar .action-btn:hover {
  border: 1px solid #eee;
}
.app-nav .navbar .action-btn--icon-only {
  padding: 0.25rem;
}
.app-nav .navbar .action-btn--icon-only svg {
  height: 1.5rem;
  position: relative;
  top: 0;
  right: 0;
}
.app-nav .navbar .action-btn--sidebar-toggle {
  display: flex;
}
