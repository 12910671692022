.processing-payment-overlay {
  padding: 1rem;
  background-color: #f2f2f7;
  gap: 1.5rem;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  z-index: 2;
}
.processing-payment-overlay * {
  font-family: "Manrope", sans-serif;
}
.processing-payment-overlay__text {
  color: #1c1c1e;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
}
.processing-payment-overlay__link {
  color: #1c1c1e;
  text-decoration: underline;
}
