.dark .dropdown .actions-menu {
  background-color: #333;
}
.dark .dropdown .actions-menu .action-menu-separator {
  background-color: #444;
}
.dark .dropdown .actions-menu .action-menu-button {
  color: #ccc;
}
.dark .dropdown .actions-menu .action-menu-button svg {
  color: #ccc;
}
.dark .dropdown .actions-menu .action-menu-button:hover {
  color: white;
}
.dark .dropdown .actions-menu .action-menu-button:hover svg {
  color: white;
}

.dropdown .actions-menu {
  z-index: 999999;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  position: absolute;
  right: -0.25rem;
  padding: 3px;
  top: 25px;
}
@media (max-width: 576px) {
  .dropdown .actions-menu {
    left: -80px;
  }
}
.dropdown .actions-menu .action-menu-separator {
  height: 1px;
  background-color: #ddd;
  margin: 3px 0px;
}
.dropdown .actions-menu .action-menu-button {
  position: relative;
  cursor: pointer;
  display: block;
  font-size: 13px;
  text-decoration: none;
  color: rgb(116, 132, 144);
  white-space: nowrap;
  padding: 8px 12px 8px 6px;
  line-height: 1.5em;
}
.dropdown .actions-menu .action-menu-button.selected {
  color: #444;
  pointer-events: none;
}
.dropdown .actions-menu .action-menu-button.selected .fa {
  color: #444;
}
.dropdown .actions-menu .action-menu-button .action-button-content {
  display: inline-block;
  font-weight: 600;
  margin-left: 25px;
}
.dropdown .actions-menu .action-menu-button .action-button-description {
  font-size: 10px;
  font-weight: 500;
  position: relative;
  left: 1px;
}
.dropdown .actions-menu .action-menu-button svg {
  color: rgb(116, 132, 144);
  margin-right: 2px;
  display: inline-block;
  vertical-align: top !important;
  margin-top: -9px;
  top: 50%;
  position: relative;
  font-size: 16px;
  position: absolute;
}
.dropdown .actions-menu .action-menu-button:hover {
  color: #38566f;
}
.dropdown .actions-menu .action-menu-button:hover svg {
  color: #38566f;
}
