.features-skeleton {
  gap: 10rem;
  margin-bottom: 3rem;
}
.features-skeleton .feature-skeleton-item {
  width: 80%;
  gap: 4rem;
  margin: 0 auto;
}
.features-skeleton .feature-skeleton-item:nth-child(2n) {
  flex-direction: row-reverse;
}
.features-skeleton .feature-skeleton-text {
  gap: 1rem;
  width: 100%;
}
.features-skeleton .feature-skeleton-image {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  scale: 0.92;
}
@media (max-width: 36rem) {
  .features-skeleton {
    gap: 2rem;
  }
  .features-skeleton .feature-skeleton-item {
    flex-direction: column-reverse !important;
    gap: 1rem;
  }
}
