.uploader {
  gap: 2rem;
  padding: 2rem 0 0;
  position: relative;
  text-align: center;
}
.uploader:hover {
  cursor: pointer;
}
.uploader .uploader__title {
  font-size: 2.5rem;
  line-height: 1em;
  font-weight: 800;
  letter-spacing: -1px;
}
.uploader__drag {
  border: dashed 1px #007aff !important;
  background-color: #f1f6fe;
}
.uploader__dropzone {
  width: 100%;
}
.uploader__copy {
  font-size: 1.125rem;
  letter-spacing: -0.5px;
  line-height: 2rem;
}
.uploader__copy > p {
  margin-bottom: 0;
}
.uploader__cta {
  background-color: #007aff;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  padding: 1.5rem 1.25rem;
}
.uploader__cta:active, .uploader__cta:focus, .uploader__cta:hover {
  background: #007aff;
}
.uploader__overlay {
  background-color: white;
  border-radius: 0.75rem;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.uploader__overlay__text {
  font-size: 1.5rem;
  font-weight: 500;
}
@media (max-width: 48rem) {
  .uploader__copy--hidden-on-small {
    display: none;
  }
}
@media (min-width: 48rem) {
  .uploader {
    border: dashed 1px #ced4da;
    border-radius: 0.75rem;
    padding: 3.125rem 2.5rem;
  }
  .uploader .uploader__title {
    font-size: 3.5rem;
  }
}
@media (min-width: 75rem) {
  .uploader .uploader__title {
    font-size: 5rem;
    line-height: 6.125rem;
  }
  .uploader__cta {
    border-radius: 0.75rem;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
  }
}
@media (min-width: 100rem) {
  .uploader {
    gap: 2rem;
    padding: 3.125rem 2.5rem;
  }
}
