.dark .modal-dialog .modal-body {
  color: #ccc;
}
.dark .modal-dialog .modal-body b {
  color: white;
}
.dark .modal-dialog .modal-header,
.dark .modal-dialog .modal-title {
  color: #ccc;
  border-color: #444;
}
.dark .modal-dialog .modal-header .close,
.dark .modal-dialog .modal-title .close {
  color: white;
}
.dark .modal-dialog .modal-header .close:hover,
.dark .modal-dialog .modal-title .close:hover {
  color: white;
}
.dark .modal-dialog .modal-footer {
  border-color: #444;
}
.dark .modal-dialog .modal-footer .blue-button[disabled] {
  background: #232323;
  color: rgba(204, 204, 204, 0.38);
}
.dark .modal-dialog .modal-footer .danger-button {
  background: #ff6961;
  color: white;
}
.dark .modal-dialog .modal-footer .danger-button:hover {
  background: #ff6961;
}
.dark .modal-dialog .modal-footer .danger-button[disabled] {
  background-color: #333333;
  color: rgba(204, 204, 204, 0.38);
}
.dark .modal-dialog .modal-content {
  background: #333;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1100 !important;
}

.modal-title {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #38566f;
}

.modal-dialog .modal-header {
  padding: 12px 18px 10px 18px;
  border-bottom: none;
}
.modal-dialog .modal-header .close {
  margin: -13px -18px -15px auto !important;
  padding: 18px !important;
  box-shadow: none;
  border: none;
  font-size: 14px;
}
.modal-dialog .modal-header .close:hover {
  background: none;
}
.modal-dialog .modal-content {
  pointer-events: auto;
  border-radius: 3px;
}
.modal-dialog .modal-body {
  color: #333;
  padding: 18px;
  font-size: 14px;
}
.modal-dialog .modal-body b {
  color: black;
  font-weight: 600;
}
.modal-dialog .modal-footer {
  padding: 0px 18px 15px 18px;
  border-top: none;
}
.modal-dialog .modal-footer button {
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  padding: 7px 14px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.modal-dialog .modal-footer .blue-button {
  background: #007aff;
  border: none;
  color: white;
  position: relative;
}
.modal-dialog .modal-footer .blue-button::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal-dialog .modal-footer .blue-button::after {
  background-color: white;
}
.modal-dialog .modal-footer .blue-button:active, .modal-dialog .modal-footer .blue-button:focus, .modal-dialog .modal-footer .blue-button:hover {
  background: #007aff;
  color: white;
}
.modal-dialog .modal-footer .blue-button:active::after, .modal-dialog .modal-footer .blue-button:focus::after, .modal-dialog .modal-footer .blue-button:hover::after {
  opacity: 0.08;
}
.modal-dialog .modal-footer .blue-button[disabled] {
  background: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.modal-dialog .modal-footer .danger-button {
  background: #d70015;
  border: none;
  color: white;
}
.modal-dialog .modal-footer .danger-button:hover {
  background: #d70015;
  color: white;
}
.modal-dialog .modal-footer .danger-button[disabled] {
  background-color: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
}
.modal-dialog .modal-footer .btn-link {
  color: #838ea2;
  padding: 7px 5px;
  text-decoration: none;
  border: none;
  box-shadow: none;
}
.modal-dialog .modal-footer .btn-link:focus {
  text-decoration: none;
}
.modal-dialog .modal-footer .btn-link:hover {
  color: #38566f;
  text-decoration: none;
}
.modal-dialog .modal-footer .btn-link.active {
  color: #059e05;
}

.dark .toolarge-modal .toolarge-modal-content {
  color: #aaa;
}
.dark .toolarge-modal .toolarge-modal-content-header {
  color: white;
}
.dark .toolarge-modal .modal-footer .close-button {
  border: none;
  color: #bbb;
}
.dark .toolarge-modal .modal-footer .close-button:hover {
  background: none;
  color: white;
}
.dark .toolarge-modal .toolarge-modal-content-features .feature {
  color: #ddd;
}
.dark .toolarge-modal .toolarge-modal-content-features .feature div {
  border-bottom: 2px solid white;
}

.toolarge-modal .toolarge-modal-tab {
  position: absolute;
  left: 20px;
  top: 0px;
  background: #0f90fa;
  color: white;
  font-weight: 600;
  padding: 4px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
}
.toolarge-modal .modal-body {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}
.toolarge-modal .modal-body img {
  height: 120px;
  margin: 40px;
}
.toolarge-modal .modal-footer {
  justify-content: center;
  padding-bottom: 30px !important;
}
.toolarge-modal .modal-footer .blue-button {
  margin-left: 8px;
}
.toolarge-modal .toolarge-modal-content {
  text-align: center;
  color: rgb(116, 132, 144);
}
.toolarge-modal .toolarge-modal-content-header {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
