.footer-v3-section__title {
  color: #232554;
  font-size: 1.125rem;
  font-weight: 800;
}
.footer-v3-section__navigation-list {
  gap: 1.25rem;
  list-style: none;
  margin: 1.25rem 0 0;
  padding: 0;
}
.footer-v3-section__navigation-list__item {
  line-height: 1;
}
