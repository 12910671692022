@keyframes animation-wave {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
.sb-skeleton {
  background-color: rgba(0, 0, 0, 0.11);
  display: block;
  width: 100%;
}
.sb-skeleton--circular {
  border-radius: 50%;
}
.sb-skeleton--text {
  border-radius: 4px/6.7px;
}
.sb-skeleton--rectangular {
  border-radius: 0;
}
.sb-skeleton.sb-skeleton-animated--wave {
  overflow: hidden;
  position: relative;
}
.sb-skeleton.sb-skeleton-animated--wave:after {
  animation: animation-wave 1.6s linear 0.5s infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  mask-image: -webkit-radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  right: 0;
  top: 0;
  transform: translateX(-100%);
}
