.long-text-section-container .long-text-section {
  padding: 6.25rem 0;
  max-width: 62.5rem;
  margin: 0 auto;
}
@media (max-width: 62rem) {
  .long-text-section-container .long-text-section {
    padding: 6.25rem 1.25rem;
  }
}
.long-text-section-container .long-text-section__title {
  font-size: 3.75rem;
  line-height: 4.125rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 2.5rem;
}
.long-text-section-container .long-text-section__content p {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.long-text-section-container .long-text-section__content h2,
.long-text-section-container .long-text-section__content h3 {
  margin: 3rem auto 1.5rem auto;
}
.long-text-section-container .long-text-section__content ol,
.long-text-section-container .long-text-section__content ul {
  margin-top: 1.5rem;
}
.long-text-section-container .long-text-section__content ol li,
.long-text-section-container .long-text-section__content ul li {
  font-family: Manrope, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
}
.long-text-section-container .long-text-section__content a {
  text-decoration: underline;
}
