.embed-preview-modal .sb-modal__panel__content {
  padding-top: 0;
  padding-bottom: 0;
}
.embed-preview-modal__content {
  gap: 1.5rem;
}
.embed-preview-modal__section {
  gap: 0.75rem;
}
.embed-preview-modal__code-wrapper {
  padding-top: 0.063rem;
  min-height: 6rem;
}
.embed-preview-modal__code-wrapper__code {
  flex: 1;
  resize: none;
}
.embed-preview-modal__radio-group {
  gap: 1rem;
}
.embed-preview-modal__dimensions {
  gap: 0.75rem;
}
.embed-preview-modal__dimensions .sb-input {
  max-width: 6rem;
  padding: 0.5rem;
}
.embed-preview-modal__embed-options-heading {
  font-weight: 600;
}
.embed-preview-modal__embed-option {
  gap: 0.5rem;
}
.embed-preview-modal__embed-option__label {
  display: flex;
  gap: 0.25rem;
}
.embed-preview-modal__tooltip-text > p {
  margin-bottom: 0;
}
