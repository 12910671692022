.small-print-details {
  padding-left: 1rem;
  margin-bottom: 0;
  color: #6b6d70;
  font-size: 0.75rem;
}
.small-print-details a {
  color: #6b6d70;
  text-decoration: underline;
}
.small-print-details a:hover {
  color: #232323;
  text-decoration: underline;
}
