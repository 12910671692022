.sb-toast-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1200;
  padding: 10px 8px;
}

.sb-toast {
  border-radius: 0.625rem;
  gap: 1.5rem;
  padding: 10px 12px;
}
.sb-toast__dismiss-btn {
  color: white;
}
.sb-toast__dismiss-btn:hover {
  color: white;
}

.sb-alert.sb-toast--success {
  background-color: black;
  color: #f3f3f3;
  border-color: black;
}
