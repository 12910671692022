.cursor-not-allowed {
  cursor: not-allowed;
}

.dark .video-item .video-url a,
.dark .video-item button {
  color: #ccc !important;
}
.dark .video-item .video-url a:not(.video-labels):hover,
.dark .video-item button:not(.video-labels):hover {
  color: white !important;
}
.dark .video-item .edit-title-input {
  color: white !important;
}
.dark .video-item .edit-title-input:focus {
  border-color: #ccc;
  background-color: transparent;
}
.dark .video-item.grid .error-alert {
  background-color: #232323;
  color: #ccc !important;
}
.dark .video-item.grid .error-alert button {
  color: #ccc !important;
  border: 1px solid #ccc !important;
}
.dark .video-item.grid .error-alert button:hover {
  color: white !important;
  border-color: white !important;
}

.video-item .video-url {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0px 5px;
  font-size: 0.766rem;
}
.video-item .video-url__clipboard-button {
  background: transparent;
  border: none;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 400;
  height: auto;
  margin-left: 0.25rem;
  line-height: 0.9rem;
  padding: 0;
  width: auto;
}
.video-item .video-url__clipboard-button__chip svg {
  max-height: 0.75rem;
  max-width: 0.75rem;
}
.video-item .video-url__clipboard-button:not(:hover) .video-url__clipboard-button__chip:not(.sb-chip--primary) {
  background-color: transparent;
}
.video-item #video-url-input {
  color: #007aff;
}
.video-item .video-uploaded-at {
  margin: 0;
}
.video-item:hover .video-item-checkbox {
  display: block;
}
.video-item .video-header {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  padding: 10px;
}
.video-item .video-header .video-item-checkbox {
  float: left;
}
.video-item .video-header .video-item-checkbox.rc-checkbox-checked {
  display: block;
}
.video-item .video-header .header-item {
  float: right;
  font-weight: 600;
  color: #eee;
  font-size: 11px;
  line-height: 18px;
  margin-left: 5px;
  border-radius: 3px;
  padding: 0 5px;
  text-shadow: 0px 0px 10px black;
}
.video-item .video-header a:hover {
  color: white;
}
.video-item .clipped {
  background-color: #0f90fa;
}
.video-item .clipped:hover {
  background-color: #0f90fa;
}
.video-item .button-primary {
  transition: background-color 0.2s linear;
}
.video-item .edit-title-input {
  border: 1px solid transparent;
  padding: 2px 4px;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 16px;
  color: #444;
  resize: none;
  outline: 0;
  background: transparent;
}
.video-item .edit-title-input::placeholder {
  font-weight: normal;
}
.video-item .edit-title-input:focus {
  border-color: #ccc;
  background-color: #f8f8f8;
  box-shadow: inset 0px 0px 3px #ccc;
  outline: 0;
}
.video-item .edit-title-input:hover {
  border-color: #ccc;
  outline: 0;
}
.video-item .video-thumbnail-container {
  display: inline-block;
  position: relative;
  border-radius: 0.547rem 0.547rem 0 0;
}
.video-item .video-thumbnail-container .video-details-button {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.video-item .video-thumbnail-container .play-button {
  color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  text-align: center;
  font-size: 30px;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8)); /* Same syntax as box-shadow */
  align-items: center;
  justify-content: center;
}
.video-item .video-thumbnail-container .play-button svg {
  display: none;
  pointer-events: none;
}
.video-item .video-thumbnail-container:hover .play-button svg {
  display: block;
}
.video-item.grid {
  padding: 10px;
}
.video-item.grid .card {
  line-height: 0;
  border-radius: 0.547rem;
}
.video-item.grid .card--dragged {
  opacity: 0.34;
}
.video-item.grid .card img {
  height: 120px;
  width: 100%;
  object-fit: cover;
  background-color: black;
}
.video-item.grid .card .card-block {
  line-height: 1.5;
  padding: 10px;
}
.video-item.grid .card .card-block:hover .url-actions {
  display: block;
}
.video-item.grid .card .card-block .card-metadata {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.video-item.grid .card .card-block .card-metadata__disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}
.video-item.grid .card .card-block .card-metadata__disabled a {
  color: #748490;
}
.video-item.grid .card .text-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.video-item.grid .card .text-card .text-card--header {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #ffffff;
}
.video-item.grid .card .text-card .text-card--description > span {
  font-size: 0.711rem;
  opacity: 0.6;
  color: #ffffff;
}
.video-item.grid .card .text-card .text-card--link {
  font-size: 0.711rem;
  opacity: 0.6;
  color: #ffffff;
  text-decoration: underline;
}
.video-item.grid .card .text-card .text-card--link:hover {
  color: #ffffff;
  opacity: 0.9;
}
.video-item.grid .card .card-img-top {
  border-radius: 0.547rem 0.547rem 0 0;
  min-height: 160px;
  background-color: black;
}
.video-item.grid .card .video-item-metadata {
  font-size: 10px;
  color: #aaa;
}
.video-item.grid .card .video-item-metadata #plays {
  margin-top: 2px;
  display: inline-block;
}
.video-item.grid .card .video-item-metadata a {
  color: #aaa;
}
.video-item.grid .card .video-item-metadata a:hover {
  color: #38566f;
  text-decoration: none;
}
.video-item.grid .video-thumbnail-container {
  border-radius: 0.547rem 0.547rem 0 0;
  width: 100%;
}
.video-item.grid .error-alert {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 40px;
  overflow: hidden;
  border-radius: 0.547rem;
  padding: 1.5rem;
}
.video-item.grid .error-alert p {
  text-align: center;
  padding: 10px;
  font-size: 14px;
}
.video-item.grid .error-alert button {
  color: rgb(116, 132, 144);
  background: none;
  border: 1px solid rgb(116, 132, 144);
  line-height: 20px;
  height: 30px;
}
.video-item.grid .error-alert button:hover {
  color: #38566f;
  border-color: #38566f;
}
.video-item .callout {
  z-index: 1;
  background-color: #1832db;
  position: absolute;
  bottom: -15px;
  right: -330px;
  width: 295px;
  color: white;
  padding: 18px;
  border-radius: 3px;
  margin: 25px;
  min-height: 50px;
  font-size: 13px;
}
.video-item .callout h1 {
  color: white;
  font-size: 18px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 15px;
}
.video-item .callout .callout-close {
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  top: 2px;
  right: 4px;
  padding: 10px;
  cursor: pointer;
  color: white;
}
.video-item .callout .callout-close:hover {
  color: white;
}
.video-item .callout .callout-close-button {
  background: rgba(255, 255, 255, 0.3);
  padding: 5px 30px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  height: auto;
  margin-top: 20px;
  border: none;
}
.video-item .callout .callout-close-button:hover {
  background: rgba(255, 255, 255, 0.5);
}
.video-item .callout::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
  left: -20px;
  bottom: 10px;
  border-right: 10px solid #1832db;
}

.delete-video-modal__content {
  word-wrap: break-word;
}
.delete-video-modal__content strong {
  font-weight: 600;
}
