.faq-section--lavender {
  background-color: #edeffe;
}
.faq-section__content {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  padding: 3.75rem 1.25rem;
}
.faq-section__content__title {
  margin: 0;
  text-align: center;
}
@media (min-width: 62rem) {
  .faq-section__content {
    margin: 0 auto;
    max-width: 62.5rem;
    padding: 6.25rem 0;
  }
}
