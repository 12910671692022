.pricing-plans__cadence-switch-container {
  gap: 1rem;
  margin-top: 3.75rem;
  padding: 0 1.25rem;
  text-align: center;
}
.pricing-plans__cadence-switch-container__copy-container {
  flex-grow: 1;
}
.pricing-plans__cadence-switch-container__copy-container__copy {
  display: inline-block;
  font-size: 1.125rem;
  max-width: fit-content;
}
.pricing-plans__content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.5rem 1.25rem 3.75rem;
}
@media (min-width: 48rem) {
  .pricing-plans__cadence-switch-container {
    margin: 3.75rem 0 2.5rem;
  }
  .pricing-plans__content {
    margin: 0 auto;
    max-width: 31.25rem;
    padding: 1.5rem 0;
  }
}
@media (min-width: 62rem) {
  .pricing-plans__cadence-switch-container {
    gap: 0;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding: 0 1.25rem;
    text-align: left;
  }
  .pricing-plans__content {
    border-top: 0.063rem solid #c8c8d5;
    border-bottom: 0.063rem solid #c8c8d5;
    gap: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    max-width: 75rem;
  }
  .pricing-plans__content > * {
    flex: 1 1;
  }
}
@media (min-width: 75rem) {
  .pricing-plans__cadence-switch-container {
    padding: 0;
  }
}
