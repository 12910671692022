.footer-v3 {
  padding: 3.75rem 1.25rem;
}
.footer-v3__container {
  gap: 3.875rem;
}
.footer-v3__container__column {
  flex-basis: 100%;
  gap: 3.875rem;
}
.footer-v3__copyright-text, .footer-v3__copyright-text__link {
  color: rgba(35, 37, 84, 0.64);
  font-size: 0.875rem;
  font-weight: 500;
}
.footer-v3__copyright-text__link:active, .footer-v3__copyright-text__link:focus, .footer-v3__copyright-text__link:hover {
  color: #232554;
}
@media (min-width: 62rem) {
  .footer-v3 .footer-v3 {
    padding: 6.25rem 0;
  }
  .footer-v3 .footer-v3__container {
    flex-direction: row;
    gap: 3.75rem;
    margin: 0 auto;
    max-width: 62.5rem;
  }
  .footer-v3 .footer-v3__container__column {
    flex-basis: 33.3333%;
    gap: 3.875rem;
  }
}
