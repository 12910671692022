.dark .settings a {
  color: #aaa;
}
.dark .settings a:hover {
  color: white;
}
.dark .settings .section .section-action a:hover,
.dark .settings .section .section-action button:hover {
  color: white;
}
.dark .settings .StripeElement {
  background-color: white;
}
.dark .settings button:disabled {
  color: #aaa;
}
.dark .settings .label-subtext {
  color: rgb(116, 132, 144);
}
.dark .settings .card .card-footer {
  background-color: #333;
  border-color: #444;
}
.dark .settings .card .card-footer a {
  border-color: #444;
}
.dark .settings .card .card-footer a:hover {
  color: white;
}
.dark .settings .card .card-header {
  border-color: #333;
  color: #bbb;
}
.dark .settings .card .card-block {
  color: #aaa;
}
.dark .settings .form-control[type=text],
.dark .settings .form-control[type=password] {
  background: #555;
  color: white;
  border: 1px solid #888;
}
.dark .settings h1,
.dark .settings h2 {
  color: #ccc;
}
.dark .settings h3 {
  color: #666;
}
.dark .settings .section.separator {
  border-color: #444;
}
.dark .settings .paypal-info .paypal-name {
  border-left: 1px solid #444;
}
.dark .settings .toggle {
  background: #444;
}
.dark .settings .tab:hover {
  color: white !important;
}
.dark .settings .tab.selected:hover {
  color: #0f90fa !important;
}
.dark .settings .section {
  border-color: #444;
}
.dark .settings .card-info {
  color: white;
}
.dark .settings .loading-spinner {
  filter: grayscale(100%) brightness(1000%);
  opacity: 0.3;
}
.dark .continue-modal .modal-header .modal-title {
  color: white;
}
.dark .continue-modal textarea {
  background: #666;
  border-color: #444;
  color: #eee;
}
.dark .continue-modal .continue-copy {
  color: white;
}
.dark .continue-modal .continue-alert {
  color: white;
}
.dark .continue-modal .continue-alert .continue-features {
  font-weight: 500;
}

.retry-link {
  display: inline-block;
  color: #63abff;
  font-weight: 400;
  text-decoration: underline;
  opacity: 0.9;
  cursor: pointer;
}
.retry-link:hover {
  opacity: 1;
}

.settings {
  display: flex;
  max-width: 72rem;
  padding: 1.5rem 3.625rem;
  width: 100%;
}
@media (max-width: 800px) {
  .settings {
    padding: 1rem;
  }
}
.settings .card {
  border-radius: 10px;
}
.settings .loading-spinner {
  margin: 40px auto;
  height: 30px;
}
.settings .settings-sidebar {
  padding: 20px 10px;
  flex: 0 0 240px;
}
@media (max-width: 800px) {
  .settings .settings-sidebar {
    display: none;
  }
}
@media (max-width: 950px) {
  .settings .settings-sidebar.right {
    display: none;
  }
}
@media (max-width: 1200px) {
  .settings .settings-sidebar.right {
    flex: 0 0 160px;
  }
}
.settings .settings-sidebar .tab {
  padding: 0.5rem;
  display: flex;
  font-size: 1rem;
  font-weight: normal;
  color: rgb(116, 132, 144);
}
.settings .settings-sidebar .tab:hover {
  color: black;
}
.settings .settings-sidebar .tab .tab-icon {
  padding-right: 10px;
  text-align: center;
}
.settings .settings-sidebar .tab.selected {
  color: #007aff;
  font-weight: 600;
}
.settings .settings-content {
  flex: 1;
  height: 100%;
}
.settings button {
  font-weight: 600;
}
.settings .card-footer .blue-button {
  margin: 5px;
}
.settings .alert {
  margin: 20px auto 20px auto;
  display: flex;
  align-items: center;
  width: 100%;
}
.settings .alert div {
  flex: 1;
}
.settings .alert span {
  font-weight: 600;
}
.settings .alert a {
  font-weight: 600;
  margin-left: 10px;
  color: #38566f;
}
.settings .form-check-input {
  margin-top: 4px;
  margin-left: -24px;
}
.settings .form-check-label {
  padding-left: 2rem;
  cursor: pointer;
}
.settings .label-subtext {
  color: #bbb;
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
}
.settings .card {
  color: rgb(116, 132, 144);
  padding: 1.875rem;
  width: 100%;
}
.settings .card .card-header {
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  color: rgb(116, 132, 144);
}
.settings .card .card-title {
  color: rgb(116, 132, 144);
  margin-bottom: 20px;
}
.settings .card .card-block {
  padding-bottom: 8px;
}
.settings .card .card-footer {
  background-color: rgb(35, 35, 35);
  border-color: #333;
  line-height: 1.5;
  padding: 5px;
  background-color: #f9f9f9;
  border-color: rgba(0, 0, 0, 0.05);
}
.settings .card .card-footer a {
  border: none;
  background: none;
  padding: 0px 26px;
  height: 26px;
  line-height: 24px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  float: right;
}
@media (max-width: 1200px) {
  .settings .card .card-footer a {
    padding: 0px 15px;
  }
  .settings .card .card-footer a .extra {
    display: none;
  }
}
.settings .card .card-footer a svg {
  margin-right: 5px;
}
.settings .card .card-footer a:hover {
  background: none;
  color: #38566f;
}
.settings .card .card-footer .rightmost {
  border-right: none;
}
.settings .form-group > div {
  align-items: center;
  display: flex;
}
.settings .signin-alert {
  margin-bottom: 10px;
  clear: both;
}
.settings .signin-alert.error {
  color: red;
}
.settings .header {
  display: flex;
  align-items: center;
  margin: 10px 14px;
}
.settings .ad-settings {
  padding: 15px;
}
.settings .ad-settings .embed-section-header {
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  margin-top: 16px;
  padding-bottom: 2px;
  color: #aaa;
  font-size: 12px;
}
.settings .ad-settings .embed-section-header:first-of-type {
  margin-top: 0px;
}
.settings .ad-settings .embed-inline-control {
  display: inline-block;
}
.settings .ad-settings .embed-inline-control input[type=radio] {
  margin-right: 10px;
  position: relative;
  top: -1px;
  margin-left: 2px;
}
.settings .ad-settings .embed-inline-control input[type=checkbox] {
  margin-right: 2px;
  position: relative;
  top: -1px;
}
.settings .ad-settings .embed-inline-control span {
  display: inline-block;
  margin-right: 15px;
}
.settings .ad-settings .embed-inline-control input[type=number] {
  max-width: 200px;
}
.settings .ad-settings input[type=text],
.settings .ad-settings input[type=number] {
  margin-bottom: 10px;
}
.settings .ad-settings label {
  cursor: pointer;
  user-select: none;
  min-width: 112px;
}
.settings .ad-settings label input {
  cursor: pointer;
}
.settings .StripeElement {
  padding: 0.6rem 0.75rem;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 35.5px;
}
.settings h1 {
  color: #333;
  font-weight: 600;
}
.settings h2 {
  font-weight: 600;
  color: #444;
  font-size: 16px;
  margin-bottom: 4px;
}
.settings h3 {
  font-size: 16px;
  margin-bottom: 0;
}
.settings .saved {
  font-size: 10px;
  text-align: left;
  margin-top: 5px;
}
.settings .section {
  margin-top: 0px;
  padding-top: 30px;
  display: flex;
  align-items: center;
}
.settings .section.separator {
  border-top: 1px solid #eee;
  margin-top: 30px;
}
.settings .section .section-info {
  flex: 1;
}
.settings .section .section-action {
  flex: 0 0 140px;
  text-align: center;
  user-select: none;
  position: relative;
  align-items: center;
}
.settings .section .section-action button {
  color: #007aff;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0;
}
.settings .section .section-action button:hover {
  color: black;
}
.settings .section .section-action a {
  color: #007aff;
  font-weight: 600;
}
.settings .section .section-action a:hover {
  color: black;
}
.settings .section .section-action a.cancel {
  color: #dc3545;
  font-weight: 600;
}
.settings .section .section-action a.cancel:hover {
  color: #38566f;
}
.settings .section .section-action-text {
  flex: 0 0 250px;
}
.settings .section--column {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.settings .section--column .section-action {
  display: flex;
  flex: 0;
  gap: 1.5rem;
}
.settings .paypal-info {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
}
.settings .paypal-info img {
  height: 40px;
}
.settings .paypal-info .paypal-name {
  border-left: 1px solid #ddd;
  margin-left: 15px;
  padding-left: 15px;
}
.settings .card-info {
  display: flex;
  align-items: center;
  justify-content: left;
  color: #333;
}
.settings .card-info img {
  height: 26px;
  width: 42px;
  border-radius: 4px;
  margin-right: 10px;
}
.settings .card-info .asterisk {
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .settings .card-info .asterisk {
    width: 76px;
    overflow: hidden;
    white-space: nowrap;
  }
  .settings .card-info .card + .asterisk {
    width: 40px;
  }
}
.settings .card-info .last4 {
  font-weight: 600;
  margin-left: 10px;
  font-size: 16px;
  font-family: monospace, monospace;
}
.settings .card-info .exp {
  margin-left: 10px;
  color: #aaa;
  font-weight: 600;
  font-size: 16px;
  font-family: monospace, monospace;
}
.settings .card-info .card-number {
  margin: 0px 20px;
}
.settings .card-info .float-right {
  text-align: right;
  flex: 1;
}
.settings .toggle {
  margin: 0px auto;
  cursor: pointer;
  height: 28px;
  width: 60px;
  background: #bbb;
  border-radius: 14px;
  position: relative;
}
.settings .toggle .toggle-knob {
  background: white;
  border-radius: 13px;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 2px;
  top: 2px;
}
.settings .toggle.selected {
  background: #0f90fa;
}
.settings .toggle.selected .toggle-knob {
  right: 2px;
  left: inherit;
}
.settings .hidden .section-info,
.settings .hidden .section-action {
  opacity: 0.3;
  pointer-events: none;
}
.settings .stat-value {
  padding: 10px;
  font-size: 16px;
}

.payments {
  max-width: 600px;
  width: 100%;
  margin: 2rem 1rem !important;
}
.payments a {
  font-weight: 600;
}
.payments .table td {
  border: none;
}

.card.change-payment,
.card.password {
  padding: 0px;
  margin: 30px auto;
  max-width: 400px;
}

.profile .alert-stale {
  margin-bottom: 0px;
}

.continue-modal .modal-header .modal-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #333333;
}
.continue-modal .modal-body {
  padding: 1.25rem 1rem;
  text-align: left;
}
.continue-modal .modal-footer {
  justify-content: end;
}
.continue-modal .continue-copy {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #333333;
}
.continue-modal .continue-alert {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #333333;
}
.continue-modal .continue-alert .continue-features {
  padding: 0;
  margin: 0;
  margin-top: 1rem;
}
.continue-modal .continue-alert .continue-features .continue-feature {
  display: flex;
  align-items: center;
  padding: 0;
}
.continue-modal .continue-alert .continue-features .continue-feature svg {
  font-size: 1rem;
  display: block;
  margin-right: 0.5rem;
}
.continue-modal .continue-alert .continue-features .continue-feature:not(:last-child) {
  margin-bottom: 0.75rem;
}

.update-card--back .back {
  position: absolute;
  top: 0px;
  left: 15px;
  font-size: 40px;
  color: #333;
  padding: 10px;
  z-index: 9;
}
.update-card--back .back:hover {
  color: black;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
