.hero-video-ticker {
  display: none;
}
@media (min-width: 48rem) {
  .hero-video-ticker {
    align-items: center;
    display: flex;
    bottom: 4rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 2.5rem;
    position: absolute;
    z-index: 1;
  }
  .hero-video-ticker__video {
    background-color: #edeffe;
    border-radius: 0.625rem;
    height: 12.5rem;
    width: 22.25rem;
  }
}
