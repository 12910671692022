@keyframes animation-wave {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
.shimmering-logo {
  background-color: #c7c7cc;
  clip-path: url(#streamable-logo);
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 2rem;
}
.shimmering-logo--animated {
  background-color: rgba(0, 0, 0, 0.11);
}
.shimmering-logo--animated:after {
  animation: animation-wave 1.6s linear 0.5s infinite;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  mask-image: -webkit-radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  right: 0;
  top: 0;
}
