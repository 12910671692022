.video-captions-modal .sb-modal__panel {
  max-width: 26rem;
  margin: 1rem;
}
.video-captions-modal .sb-modal__panel__header {
  padding-bottom: 0.625rem;
}
.video-captions-modal .error-container {
  color: #dc3545;
  margin-bottom: 1rem;
}
.video-captions-modal__caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.video-captions-modal__caption:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}
.video-captions-modal__caption-name {
  display: flex;
  align-items: center;
  height: 2.25rem;
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.video-captions-modal__caption-delete {
  display: none;
}
.video-captions-modal__caption-delete--mobile {
  display: flex;
}
.video-captions-modal__caption:hover .video-captions-modal__caption-delete {
  display: flex;
}
.video-captions-modal__actions {
  margin: 1.25rem 0 1rem 0;
}
.video-captions-modal__actions--empty {
  margin-top: 0;
}

.dark .video-captions-modal__caption-name {
  color: #cccccc;
}
