.get-started-for-free-banner {
  background-color: #edeffe;
  text-align: center;
}
.get-started-for-free-banner__content {
  padding: 6.25rem 1.25rem;
}
.get-started-for-free-banner__text {
  font-size: 1.25rem;
  margin: 0.625rem 0 0;
  opacity: 0.9;
}
.get-started-for-free-banner__cta {
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  gap: 0.625rem;
  font-weight: 600;
  line-height: 1.2em;
  padding: 0.9rem 1.25rem;
  background: #007aff;
  border: none;
  color: white;
  position: relative;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  margin-top: 2rem;
}
.get-started-for-free-banner__cta::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.get-started-for-free-banner__cta::after {
  background-color: white;
}
.get-started-for-free-banner__cta:active, .get-started-for-free-banner__cta:focus, .get-started-for-free-banner__cta:hover {
  background: #007aff;
  color: white;
}
.get-started-for-free-banner__cta:active::after, .get-started-for-free-banner__cta:focus::after, .get-started-for-free-banner__cta:hover::after {
  opacity: 0.08;
}
.get-started-for-free-banner__cta[disabled] {
  background: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
@media (min-width: 48rem) {
  .get-started-for-free-banner__cta {
    margin-top: 2.625rem;
  }
}
@media (min-width: 75rem) {
  .get-started-for-free-banner__content {
    margin: 0 auto;
    max-width: 78rem;
    padding: 6.25rem;
  }
  .get-started-for-free-banner__text {
    font-size: 1.625rem;
  }
}
