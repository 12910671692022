.videos-search-and-sort {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 0px 15px;
  position: relative;
}
.videos-search-and-sort .filter-input {
  background: #f1f1f1;
  border: none;
  border-bottom: 1px solid #888;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  left: 15px;
  outline: 0;
  position: absolute;
  top: 1px;
  width: 240px;
}
.videos-search-and-sort .search-button,
.videos-search-and-sort .dropdown.sort-button button {
  background: none;
  border: none;
  padding: 0px;
  color: #444;
  font-size: 14px;
}
.videos-search-and-sort .search-button svg,
.videos-search-and-sort .dropdown.sort-button button svg {
  margin-right: 9px;
}
.videos-search-and-sort .search-button:hover,
.videos-search-and-sort .dropdown.sort-button button:hover {
  color: black;
}
.videos-search-and-sort .dropdown.sort-button .actions-menu {
  left: -10px;
}
.videos-search-and-sort .dropdown.sort-button .actions-menu .action-button-content {
  margin-left: 0;
}
.videos-search-and-sort .dropdown.sort-button .actions-menu .action-menu-button {
  padding: 5px 12px 5px 6px;
}
.videos-search-and-sort .dropdown.sort-button .actions-menu .actions-menu-selected {
  color: black;
}

.dark .videos-search-and-sort button {
  color: #ddd;
}
.dark .videos-search-and-sort button:hover {
  color: white;
}
.dark .videos-search-and-sort .filter-input {
  background: #151515;
  border-bottom: 1px solid #444;
  color: white;
}
.dark .videos-search-and-sort .actions-menu-selected {
  color: white !important;
}
