@import '../../../common/scss/variables.scss';
@import '../../../common//scss//loop/variables.scss';

.dark {
  .labels-empty-state {
    background-color: $color-grey-900;
    color: $color-grey-300;
    border: 1px solid $color-grey-750;

    &__dismiss-btn {
      &__icon {
        color: rgba($color-grey-300, $opacity-secondary);
      }
    }
  }
}

.labels-empty-state {
  position: relative;
  gap: 0.75rem;
  padding: 1rem;
  background-color: $color-white;
  text-align: center;
  border-radius: 0.75rem;
  box-shadow: $shadow-sm;
  border: 1px solid $color-grey-125;
  color: $color-grey-800;

  &__icon {
    font-size: 1.5rem;
  }

  &__dismiss-btn {
    position: absolute;
    top: 0.375rem;
    right: 0.375rem;
    padding: 0;
    background-color: transparent !important;

    &__icon {
      color: rgba($color-grey-800, $opacity-secondary);
      font-size: 1.5rem;
    }
  }

  &__create-btn {
    width: 100%;
    height: 2.25rem;
    padding: 0 1.125rem;
  }
}
