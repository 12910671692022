.banner-bar {
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  line-height: 1.5rem;
}
.banner-bar .top_banner__close-btn {
  padding: 0;
}
.banner-bar--danger {
  background: #d70015;
  color: white;
}
.banner-bar--danger .top_banner__close-btn {
  color: white;
}
.banner-bar--danger .top_banner__close-btn:hover {
  color: white;
}
.banner-bar--warning {
  background: #ffe352;
  color: #333333;
}
.banner-bar--warning .top_banner__close-btn {
  color: #333333;
  background: transparent;
}
.banner-bar--warning .top_banner__close-btn:hover {
  color: #333333;
}
