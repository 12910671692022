.dark .user-menu-item {
  color: #eee;
}
.dark .user-menu-item--dark-mode-toggle:hover {
  background: inherit;
  color: #eee;
}

.user-menu-item {
  padding: 0.375rem 0.75rem;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.user-menu-item__icon {
  width: 2rem;
  height: 1.5rem;
}
.user-menu-item:hover {
  background: #007aff;
  color: white;
}
.user-menu-item.dark-mode:hover {
  background: inherit;
  color: black;
  cursor: pointer;
}

.user-menu-item--dark-mode-toggle:hover {
  background: inherit;
  color: #333;
}
