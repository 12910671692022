.hero-line-expression {
  stroke-dasharray: 2000;
  animation-name: line-expression-draw;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.44, 0, 0.56, 0.1);
  animation-fill-mode: forwards;
}

@keyframes line-expression-draw {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
