.features-v2__content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 2.5rem 1.25rem;
  text-align: center;
}
.features-v2__feature-separator-container {
  display: none;
}
@media (min-width: 48rem) {
  .features-v2__content {
    gap: 0;
    padding: 6.25rem 2.5rem;
    text-align: left;
  }
  .features-v2__feature-separator-container {
    display: block;
    height: 6.25rem;
    overflow: hidden;
    text-align: center;
  }
  .features-v2__feature-separator-container__feature-separator-line {
    color: #59d9a1;
    transform: translate(-5rem, -17.5rem);
  }
}
@media (min-width: 75rem) {
  .features-v2__content {
    margin: 0 auto;
    max-width: 62.5rem;
    padding: 6.25rem 0;
  }
  .features-v2__feature-separator-container {
    height: 8.635rem;
  }
}
