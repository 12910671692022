.sb-alert {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.sb-alert.sb-alert--success {
  color: #357425;
  background-color: #f0fef5;
  border: 1px solid #bee2cf;
}
.sb-alert.sb-alert--success a, .sb-alert.sb-alert--success a:hover {
  color: #357425;
  text-decoration: underline;
}
.sb-alert.sb-alert--warning {
  color: #333333;
  background-color: #fff8d4;
  border: 1px solid #ffe352;
}
.sb-alert.sb-alert--warning a, .sb-alert.sb-alert--warning a:hover {
  color: #333333;
  text-decoration: underline;
}
.sb-alert.sb-alert--danger {
  color: #692326;
  background-color: #f3d8da;
  border: 1px solid #f3d8da;
}
.sb-alert.sb-alert--danger a, .sb-alert.sb-alert--danger a:hover {
  color: #692326;
  text-decoration: underline;
}
.sb-alert.sb-alert--info {
  color: #333333;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
}
.sb-alert.sb-alert--info a, .sb-alert.sb-alert--info a:hover {
  color: #333333;
  text-decoration: underline;
}
.sb-alert p {
  flex-grow: 1;
  margin: 0;
}
.sb-alert .sb-alert__action {
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.5rem;
  box-sizing: border-box;
  flex-shrink: 0;
  white-space: nowrap;
  margin-left: 1.5rem;
  border-radius: 0.25rem;
}
