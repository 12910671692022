.sb-flex {
  display: flex;
}
.sb-flex--inline {
  display: inline-flex;
}
.sb-flex--column {
  flex-direction: column;
}
.sb-flex--row {
  flex-direction: row;
}
.sb-flex--align-items-flex-start {
  align-items: flex-start;
}
.sb-flex--align-items-center {
  align-items: center;
}
.sb-flex--align-items-flex-end {
  align-items: flex-end;
}
.sb-flex--align-items-stretch {
  align-items: stretch;
}
.sb-flex--justify-content-flex-start {
  justify-content: flex-start;
}
.sb-flex--justify-content-center {
  justify-content: center;
}
.sb-flex--justify-content-flex-end {
  justify-content: flex-end;
}
.sb-flex--justify-content-space-around {
  justify-content: space-around;
}
.sb-flex--justify-content-space-between {
  justify-content: space-between;
}
.sb-flex--justify-content-space-evenly {
  justify-content: space-evenly;
}
