@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}
@keyframes animate-linear-bg {
  to {
    --bg-angle: 360deg;
  }
}
.modal-backdrop:has(.full-modal) {
  z-index: 2000 !important;
}

body:has(.modal-backdrop .full-modal) {
  overflow: hidden;
}

.full-modal {
  margin: 0 !important;
  min-width: 100% !important;
  width: 100% !important;
}
.full-modal .modal-content {
  height: 100vh;
  border: none;
  background: linear-gradient(var(--bg-angle), #007aff 13.4%, #9543ff 86.6%) !important;
  animation: animate-linear-bg 20s linear infinite;
}
.full-modal .modal-header .close {
  opacity: 1;
}
.full-modal .modal-header .close:hover {
  opacity: 0.8;
}
.full-modal .modal-header .close .modal-title--x {
  color: #ffffff;
  font-size: 1.5rem;
}
.full-modal .modal-body .container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 100%;
}
.full-modal .modal-body .container .upgrade-modal--title {
  font-size: 3rem;
  font-weight: 600;
  line-height: 110%;
  color: white;
}
.full-modal .modal-body .container .upgrade-modal--subtitle p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: white;
  margin: 0.5rem 0;
}
.full-modal .modal-body .container .upgrade-modal--subtitle p span {
  display: block;
  margin-bottom: 0.5rem;
}
@media (max-width: 426px) {
  .full-modal .modal-body .container .upgrade-modal--subtitle p span {
    display: inline;
  }
}
.full-modal .modal-body .container .upgrade-moda--cta button {
  font-size: 1rem;
  background-color: white;
  line-height: 24px;
  color: #151515;
}
.full-modal .modal-body .container .upgrade-moda--cta .upgrade-modal-cta-trial {
  margin-top: 0.75rem;
  color: white;
  font-size: 0.875rem;
}
.full-modal .modal-body .container .upgrade-modal-cta-cancel {
  text-decoration: underline;
  color: #ffffff;
  font-size: 0.875rem;
  cursor: pointer;
}
