@import '../../common/scss/variables.scss';

.dark {
  .unlabeled-empty-state {
    &__heading {
      color: $color-grey-300;
    }

    &__description {
      color: rgba($color-grey-300, $opacity-secondary);
    }
  }
}

.unlabeled-empty-state {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-grey-800;
  gap: 0.75rem;
  text-align: center;

  &__emoji {
    font-size: 2rem;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    font-weight: $font-weight-semibold;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  &__description {
    font-weight: $font-weight-regular;
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgba($color-grey-800, $opacity-secondary);
  }
}
