@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:500");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,600");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
.visibility--screen-reader {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal;
}

.text--capitalize {
  text-transform: capitalize;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgb(116, 132, 144);
  font-size: 16px;
  background-color: #f1f1f1;
}
body *,
html * {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html.v2 {
  background-color: white;
}
html.v2 body {
  background-color: white;
  color: #232554;
  font-family: "Manrope", sans-serif !important;
}
html.v2 body * {
  font-family: "Manrope", sans-serif;
}
html.v2 h1 {
  color: #232554;
  font-size: 2.75rem;
  font-weight: 800;
  line-height: 1em;
  margin: 0;
}
html.v2 h2 {
  color: #232554;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3em;
}
html.v2 h3 {
  color: #232554;
}
@media (min-width: 48rem) {
  html.v2 h1 {
    font-size: 3.375rem;
  }
  html.v2 h2 {
    font-size: 2.5rem;
  }
}
@media (min-width: 62rem) {
  html.v2 h1 {
    font-size: 4rem;
  }
}
html.v2.dark {
  background-color: white !important;
}
html.v2.dark body {
  background-color: white !important;
}

select option {
  color: black;
}

button:focus {
  outline: 0 !important;
}

button,
.button {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  color: rgb(116, 132, 144);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 4px 12px;
  background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f5f8fa;
  border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.17) rgba(16, 22, 26, 0.1);
  background-clip: padding-box;
}
button:hover,
.button:hover {
  color: #38566f;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #f1f1f1;
  border-color: rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.2) rgba(16, 22, 26, 0.27) rgba(16, 22, 26, 0.2);
  text-decoration: none;
}
button[disabled],
.button[disabled] {
  background-color: #ccc;
  pointer-events: none;
  color: white;
}
button.active,
.button.active {
  background-color: #0f90fa;
  color: white;
  border-color: #0f90fa;
}

.form-control::-webkit-input-placeholder {
  color: #aaa;
}

.form-control:-moz-placeholder {
  color: #aaa;
}

.form-control::-moz-placeholder {
  color: #aaa;
}

.form-control:-ms-input-placeholder {
  color: #aaa;
}

a:hover {
  text-decoration: none;
}

.button-primary {
  background: #0d8050;
  background: linear-gradient(to bottom, rgba(100, 255, 255, 0.2), rgba(0, 0, 0, 0.1)) left no-repeat, center no-repeat #059e05;
  border-color: rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.1) rgba(16, 22, 26, 0.3) rgba(16, 22, 26, 0.1);
  color: white;
}
.button-primary:hover {
  color: white;
  background: #078607;
}
.button-primary svg {
  position: relative;
  top: -1px;
  font-size: 16px;
}

.btn-danger {
  background: #e91e63;
}
.btn-danger:hover {
  background: #d61b5e;
}

form {
  margin: 0px;
}

.blue-button {
  background: #0f90fa;
  border: none;
  background-clip: border-box;
  color: white;
}
.blue-button:hover {
  background-clip: padding-box;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) left no-repeat, center no-repeat #1a70b9;
  color: white;
  border: none;
}

.btn-rose {
  background: #e91e63;
}
.btn-rose:hover {
  background: #d61b5e;
}

.form-control {
  color: rgb(116, 132, 144);
}

#HW_frame_cont {
  z-index: 10000 !important;
}

.s_p_a {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}

.card {
  margin: 0px;
  background-clip: padding-box;
  position: relative;
  background-color: white;
  border: solid 1px #e8e8e8;
  border-radius: 2px;
}
.card .card-block {
  padding: 1rem;
}

.dark .card {
  border: solid 1px #444;
}

.drop-accepted-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  margin-top: -80px;
  text-align: center;
  padding: 10px;
  color: black;
  font-weight: 600;
  font-size: 46px;
  opacity: 1;
  border-radius: 5px;
}

.drop-accepted {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.9);
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

#drift-widget-container {
  z-index: 1000 !important;
}

.rc-tooltip-inner {
  max-width: 200px;
}

*:focus {
  outline: none;
}

.acquisition-banner {
  display: none;
  font-family: "Source Sans Pro", sans-serif;
  background: black;
  position: relative;
  text-align: center;
  padding: 18px;
  background-image: url(https://statics.streamable.com/static/landing/bannerbg.jpg);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  font-size: 17px;
  font-weight: 400;
}
.acquisition-banner a {
  color: #cbe2f1;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  padding-left: 4px;
}
