.dark .selectable-plan {
  color: white;
  border: 1px solid #878787;
}
.dark .selectable-plan:hover {
  border-color: #f3f3f3;
}
.dark .selectable-plan--selected {
  border-color: #f3f3f3;
}

.selectable-plan {
  align-items: center;
  background: none;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  color: #333333;
  display: flex;
  font-size: 0.875rem;
  margin: 0;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  user-select: none;
}
.selectable-plan:hover {
  cursor: pointer;
  border-color: #bbbbbb;
}
.selectable-plan--selected {
  border-color: #007aff;
}
.selectable-plan--selected:hover {
  border-color: #007aff;
}
.selectable-plan__heading {
  font-weight: 500;
  margin-bottom: 2px;
}
.selectable-plan__label-container {
  flex-grow: 1;
}
.selectable-plan__label {
  background-color: #fff8d4;
  padding: 0.125rem 0.25rem;
  border-radius: 0.125rem;
}
.selectable-plan__display-price {
  font-weight: 600;
}
.selectable-plan__description {
  font-size: 0.75rem;
  font-weight: 400;
}
.selectable-plan__title-row {
  min-height: 1.5rem;
}
