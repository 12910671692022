.progress-overlay {
  border-radius: 0.625rem 0.625rem 0 0;
  font-size: 18px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}
.progress-overlay .progress-row {
  padding: 4px;
  opacity: 0.5;
  position: relative;
}
.progress-overlay .progress-row svg {
  color: white;
}
.progress-overlay .progress-row.active {
  opacity: 1;
}
.progress-overlay .progress-row.active svg {
  animation: pulse 1s infinite ease;
}
.progress-overlay .speed {
  font-size: 10px;
  color: white;
  position: absolute;
  top: 12px;
  width: 100%;
}
.progress-overlay .progress-bar-track {
  vertical-align: middle;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  width: 160px;
  margin: 0px 10px;
}
.progress-overlay .progress-bar-value {
  background-color: rgba(255, 255, 255, 0.9);
  height: 30px;
  position: relative;
  width: 0%;
  -webkit-transition: width 1s linear;
  -moz-transition: width 1s linear;
  transition: width 1s linear;
}
.progress-overlay .progress-spinner {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  height: 30px;
  margin: 0px auto;
  margin-top: -50px;
}
.progress-overlay .progress-status {
  font-size: 14px;
  color: white;
  line-height: 20px;
  height: 20px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.progress-overlay .progress-cancel {
  padding: 20px;
  font-size: 14px;
}
.progress-overlay .progress-cancel a {
  color: white;
  font-weight: bold;
}
.progress-overlay .progress-cancel a:hover {
  color: #ddd;
}
.progress-overlay.small {
  font-size: 12px;
}
.progress-overlay.small .speed {
  display: none;
}
.progress-overlay.small .progress-bar-track {
  vertical-align: middle;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  width: 60px;
}
.progress-overlay.small .progress-bar-value {
  background-color: rgba(255, 255, 255, 0.9);
  height: 4px;
}
.progress-overlay.small .progress-row {
  padding: 1px;
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
