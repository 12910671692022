.search-labels-container {
  margin-bottom: 1rem;
}

.assign-labels-list {
  margin: 0;
  max-height: 24rem;
  overflow-y: auto;
  padding: 0;
}
.assign-labels-list__item {
  border-radius: 0.3rem;
  padding: 0.375rem;
}
.assign-labels-list__item:hover {
  background-color: #f1f1f1;
}
.assign-labels-list__item__label {
  flex-grow: 1;
  margin: 0 0.75rem;
}
.assign-labels-list__item__label:hover {
  cursor: pointer;
}
.assign-labels-list__error-message-container {
  margin-top: 0.75rem;
  padding: 0 0.375rem;
}

.dark .assign-labels-list__item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.create-labels-button {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.375rem 0.5rem;
  color: #232323;
  border-radius: 0.3125rem;
  height: 2rem;
  border: none;
  background: transparent;
}
.create-labels-button:hover {
  background-color: #f1f1f1;
  color: #232323;
}

.create-labels-button__text {
  height: 1.25rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
}

.assign-labels-create-message {
  flex-direction: row;
  align-items: center;
  padding: 0.3125rem 0.5rem;
}

@keyframes create-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.assign-labels-create-message__spinner {
  margin-right: 0.5rem;
  animation: create-spin 1s linear infinite;
}
