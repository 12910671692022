.dismissible-card {
  background-color: white;
  border-radius: 0.75rem;
  color: #232323;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
  gap: 1rem;
  padding: 1rem;
  position: fixed;
  top: 3.75rem;
  width: 100%;
  z-index: 1;
}
.dismissible-card__title {
  flex-grow: 1;
  font-size: 1.125rem;
  font-weight: 600;
}
.dismissible-card__close-btn {
  background-color: transparent;
  padding: 0;
}
.dismissible-card__close-btn:hover {
  background-color: transparent;
}
.dismissible-card__content {
  gap: 1rem;
}
.dismissible-card a {
  text-decoration: underline;
}
.dismissible-card a:hover {
  text-decoration: underline;
}
@media (max-width: 36.25rem) {
  .dismissible-card {
    left: 0;
    margin-left: 0.7rem;
    width: calc(100% - 1.4rem);
  }
}
@media (min-width: 36.25rem) {
  .dismissible-card {
    max-width: 25rem;
    right: 0.75rem;
  }
}

.dark .dismissible-card {
  background-color: #232323;
  color: white;
}
.dark .dismissible-card__close-btn {
  color: white;
}
.dark .dismissible-card__close-btn:hover {
  color: white;
}
