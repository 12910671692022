.welcome {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  z-index: 9999;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: scroll;
}
.welcome h1 {
  font-size: 50px;
  color: black;
  font-weight: 600;
  font-family: "Cabin", sans-serif;
  text-align: center;
  margin-bottom: 60px;
}
.welcome .logo {
  height: 35px;
  top: -2px;
  margin: 20px;
}
.welcome .home {
  position: absolute;
  top: 0px;
  left: 8px;
  padding: 6px;
}
@media all and (max-width: 800px) {
  .welcome .home {
    padding: 0px;
  }
  .welcome h1 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 28px;
  }
}
.welcome .uploader {
  margin: 80px 0px 120px 0px;
}
.welcome .start-form {
  display: flex;
  align-items: center;
}
@media all and (max-width: 800px) {
  .welcome .start-form {
    flex-direction: column;
  }
}
.welcome .start-form-pane {
  background: white;
  padding: 25px;
  margin: 20px;
  max-width: 300px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 8px 8px 20px #e7e7e7;
}
.welcome .start-form-pane h2 {
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 20px;
}
.welcome .start-form-pane p {
  color: #666;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  margin-bottom: 25px;
  font-weight: 500;
}
.welcome .upload .upload-button {
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 12px 20px;
  margin: 0px;
  width: 100%;
  font-weight: 600;
  background: #1f68bb;
  color: #eee;
  border-radius: 6px;
  border: none;
  position: relative;
}
.welcome .upload .upload-button:hover {
  background: #1f4d81;
}
.welcome .clip #primary-url-input {
  padding: 11px 15px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
  color: black;
}
.welcome .clip ::-webkit-input-placeholder {
  text-align: center;
}
.welcome .clip :-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}
.welcome .clip ::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}
.welcome .clip :-ms-input-placeholder {
  text-align: center;
}
.welcome .footer {
  margin: 20px 0px 50px 0px;
  width: 100%;
  text-align: center;
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 15px;
}
.welcome .footer span {
  font-family: "Source Sans Pro", sans-serif;
  padding: 0px 15px;
  display: inline-block;
}
.welcome .footer a {
  font-family: "Source Sans Pro", sans-serif;
  margin-left: 5px;
  color: #1f68bb;
}
.welcome .footer a:hover {
  color: black;
}
