.sb-text-field {
  font-size: 0.875rem;
  margin: 0;
}
.sb-text-field__label {
  gap: 0.5rem;
  margin: 0;
}
.sb-text-field .sb-input {
  font-size: 1rem;
}
.sb-text-field__hint {
  margin-top: 0.25rem;
}
.sb-text-field__error-message {
  margin-bottom: 0.21rem;
  margin-top: 0.5rem;
}
