// Colours
$color-white: white;
$color-black: black;

$color-blue-navy: #232554;
$color-blue-navy-tint: #c8c8d5;
$color-blue-navy-tint-dark: #7c7c9a;
$color-blue-indigo: #4f65ec;
$color-blue-lavender: #edeffe;
$color-blue-light: #63abff;

$color-grey-50: #f1f1f1;
$color-grey-100: #f3f3f3;
$color-grey-125: #e6e6e6;
$color-grey-150: #dddddd;
$color-grey-200: #ced4da;
$color-grey-300: #cccccc;
$color-grey-400: #bbbbbb;
$color-grey-600: #999999;
$color-grey-650: #878787;
$color-grey-700: #6b6d70;
$color-grey-750: #444444;
$color-grey-800: #333333;
$color-grey-900: #232323;

$color-grey-blue-400: #748490;

$color-green-light: #59d9a1;
$color-green-100: #f0fef5;
$color-green-200: #bee2cf;
$color-green-400: #357425;

$color-yellow-100: #fff8d4;
$color-yellow-400: #ffe352;

$color-orange-100: #ff7d66;
$color-orange-200: #f0855b;
$color-orange-450: #ff9f0a;
$color-orange-500: #ff9500;
$color-orange: $color-orange-500;

$color-red-100: #f3d8da;
$color-red-150: #ff6961;
$color-red-200: #ff4d70;
$color-red-300: #dc3545;
$color-red-400: #d70015;
$color-red-700: #692326;

$color-discord: #5765f2;

// Primary
$color-primary: #007aff;
$color-primary-text: $color-white;

// Secondary
$color-secondary: $color-white;
$color-secondary-text: $color-blue-navy;

$color-success: $color-green-100;
$color-success-text: $color-green-400;
$color-success-border: $color-green-200;

$color-warning: $color-yellow-100;
$color-warning-text: $color-grey-800;
$color-warning-border: $color-yellow-400;

$color-error: $color-red-300;
$color-error-text: $color-white;
$color-error-border: $color-red-300;

$color-info: $color-grey-100;
$color-info-text: $color-grey-800;
$color-info-border: $color-grey-100;

$color-danger: $color-red-100;
$color-danger-text: $color-red-700;
$color-danger-border: $color-red-100;

// Opacity
$opacity-secondary: 0.64;
$opacity-disabled-text: 0.64;
$opactiy-disabled-element: 0.38;

// Spacing
$spacing-tiniest: 0.5rem;
$spacing-tiny: 1rem;
$spacing-small: 1.25rem;
$spacing-medium: 1.5rem;
$spacing-large: 2.5rem;
$spacing-xl: 3.75rem;
$spacing-2xl: 6.25rem;

// Fonts
$font-size-heading-1-sm: 2.75rem; // 44px
$font-size-heading-1-md: 3.375rem; // 54px
$font-size-heading-1-lg: 4rem; // 64px
$font-size-heading-2: 2.5rem; // 40px
$font-size-heading-3: 2rem; // 32px
$font-size-heading-4: 1.5rem; // 24px
$font-size-heading-5: 1.25rem; // 20px

$font-size-label: 0.75rem; // 12px

$font-size-text-smallest: 0.75rem; // 12px
$font-size-text-small: 0.875rem; // 14px
$font-size-text-default: 1rem; // 16px
$font-size-text-medium: 1.125rem; // 18px
$font-size-text-large: 1.25rem; // 20px
$font-size-text-xl: 1.625rem; // 26px

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Shape
$border-radius-smallest: 0.125rem; // 2px
$border-radius-smaller: 0.188rem; // 3px
$border-radius-small: 0.25rem; // 4px
$border-radius-medium: 0.625rem;
$border-radius-large: 1.25rem;
$border-radius-larger: 1.5rem;
$border-radius-xl: 2.5rem;

$border-width-smallest: 0.063rem; // 1px

// Breaking points
$breaking-point-sm: 36rem;
$breaking-point-md: 48rem;
$breaking-point-lg: 62rem;
$breaking-point-xl: 75rem;

// Content
$content-max-width-md: 36.25rem;
$context-max-width-lg: 62.5rem;
$content-max-width-xl: 78rem;
