.widget-skeleton {
  margin: 1.5rem;
  margin-bottom: 0;
  height: 20rem;
  width: 80%;
  gap: 3rem;
}
.widget-skeleton .sb-skeleton:nth-child(2) {
  width: 120%;
}
.widget-skeleton .sb-skeleton:nth-child(3) {
  width: 110%;
}
@media (min-width: 48rem) {
  .widget-skeleton {
    height: 25rem;
    margin: 0;
    width: 66%;
  }
}
