.customer-logos-v2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 6.25rem 1.25rem;
  position: relative;
}
.customer-logos-v2__title {
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  text-align: center;
}
.customer-logos-v2__logos-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
}
.customer-logos-v2__logos-container > * {
  max-height: 50px;
  opacity: 0;
}
.customer-logos-v2__logos-container--appear > * {
  animation-name: logo-appear;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.customer-logos-v2__logos-container--appear > *:nth-child(1n) {
  animation-delay: 0.4s;
}
.customer-logos-v2__logos-container--appear > *:nth-child(2n) {
  animation-delay: 0.5s;
}
.customer-logos-v2__logos-container--appear > *:nth-child(3n) {
  animation-delay: 0.6s;
}
.customer-logos-v2__logos-container--appear > *:nth-child(4n) {
  animation-delay: 0.7s;
}
.customer-logos-v2__logos-container--appear > *:nth-child(5n) {
  animation-delay: 0.8s;
}
.customer-logos-v2__line-expression {
  display: none;
}
@media (min-width: 48rem) {
  .customer-logos-v2 {
    padding: 6.25rem 2.5rem;
  }
  .customer-logos-v2__logos-container {
    gap: 60px;
  }
  .customer-logos-v2__line-expression {
    color: #63abff;
    display: block;
    left: 46%;
    position: absolute;
    top: -2rem;
    transform: translateX(100%);
  }
}

@keyframes logo-appear {
  to {
    opacity: 1;
  }
}
