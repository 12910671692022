.sb-chip {
  border-radius: 0.188rem;
  gap: 0.125rem;
  padding: 0.156rem 0.25rem;
}
.sb-chip--primary {
  background-color: #007aff;
  color: white;
}
.sb-chip--secondary {
  background-color: rgba(0, 0, 0, 0.08);
  color: #6b6d70;
}

.dark .sb-chip--secondary {
  background-color: rgba(255, 255, 255, 0.08);
  color: #cccccc;
}
