.text-with-splash-expression {
  margin-right: 1rem;
  position: relative;
}
.text-with-splash-expression__expression {
  color: #ff7d66;
  position: absolute;
  right: -1rem;
  top: -0.5rem;
}
