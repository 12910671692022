.faq .faq-section__content__title {
  font-size: 3.75rem;
  line-height: 4.125rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  text-align: center;
}
.faq .sb-button {
  font-size: 1.125rem;
}
