.video-privacy-modal .sb-modal__panel {
  max-width: 37.5rem;
}
.video-privacy-modal .sb-modal__panel__header {
  border-bottom: 1px solid #e6e6e6;
}
.video-privacy-modal .sb-modal__panel__content {
  padding: 0;
}
.video-privacy-modal .privacy-and-settings {
  border: none;
  border-radius: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.dark .sb-modal__panel__header {
  border-color: #444444;
}
