.navigation-bar-v2 {
  background-color: white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
.navigation-bar-v2__content {
  display: grid;
  grid-template-areas: "logo-link menu-button" "navigation-menu navigation-menu";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  max-height: 100dvh;
  padding: 1.25rem;
}
.navigation-bar-v2__logo-container {
  align-items: center;
  display: flex;
  grid-area: logo-link;
}
.navigation-bar-v2__logo-link {
  align-items: center;
  display: flex;
}
.navigation-bar-v2 .sb-logo__mark {
  color: #007aff;
}
.navigation-bar-v2 .sb-logo__text {
  color: #232554;
}
.navigation-bar-v2__menu-btn {
  grid-area: menu-button;
}
.navigation-bar-v2__menu {
  display: none;
  grid-area: navigation-menu;
  margin: 0;
  list-style: none;
  overflow-y: auto;
  padding: 0;
}
.navigation-bar-v2__menu--open {
  display: flex;
  flex-direction: column;
}
.navigation-bar-v2__sub-menu {
  display: none;
  margin-top: 2rem;
}
.navigation-bar-v2__sub-menu--open {
  display: block;
}
.navigation-bar-v2__sub-menu__content {
  gap: 2.5rem;
}
.navigation-bar-v2__menu-item {
  border-bottom: 0.06rem solid #e6e6e6;
  padding: 0.75rem 0;
}
.navigation-bar-v2__menu-item--logo {
  display: none;
}
.navigation-bar-v2__menu-item--no-border {
  border: none;
}
.navigation-bar-v2 .navigation-bar-v2__link,
.navigation-bar-v2 .navigation-bar-v2__sub-menu-btn {
  color: rgba(35, 37, 84, 0.64);
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.navigation-bar-v2__link {
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  gap: 0.625rem;
  font-weight: 600;
  line-height: 1.2em;
  padding: 0.9rem 1.25rem;
  background: transparent;
  position: relative;
}
.navigation-bar-v2__link::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.navigation-bar-v2__link::after {
  background-color: #333333;
}
.navigation-bar-v2__link:active, .navigation-bar-v2__link:focus, .navigation-bar-v2__link:hover {
  background: transparent;
  border: none;
  color: #333333;
}
.navigation-bar-v2__link:active::after, .navigation-bar-v2__link:focus::after, .navigation-bar-v2__link:hover::after {
  opacity: 0.08;
}
.navigation-bar-v2__link[disabled] {
  background: transparent;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.navigation-bar-v2__link--cta {
  background: #007aff;
  border: none;
  color: white;
  position: relative;
  border-radius: 0.5rem;
  color: white !important;
  justify-content: center !important;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.navigation-bar-v2__link--cta::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.navigation-bar-v2__link--cta::after {
  background-color: white;
}
.navigation-bar-v2__link--cta:active, .navigation-bar-v2__link--cta:focus, .navigation-bar-v2__link--cta:hover {
  background: #007aff;
  color: white;
}
.navigation-bar-v2__link--cta:active::after, .navigation-bar-v2__link--cta:focus::after, .navigation-bar-v2__link--cta:hover::after {
  opacity: 0.08;
}
.navigation-bar-v2__link--cta[disabled] {
  background: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.navigation-bar-v2__sub-menu-btn {
  padding: 0.75rem 1.25rem;
}
.navigation-bar-v2__sub-menu-btn--active::after {
  opacity: 0.08;
}
.navigation-bar-v2__sub-menu-btn--active .navigation-bar-v2__sub-menu-btn-icon {
  transform: rotate(-180deg);
}
.navigation-bar-v2__sub-menu-btn-icon {
  margin-right: 0.9rem;
  transition: 0.2s ease-in-out transform;
}
.navigation-bar-v2--navy {
  background-color: #232554;
}
.navigation-bar-v2--navy .sb-logo__mark {
  color: white;
}
.navigation-bar-v2--navy .sb-logo__text {
  color: white;
}
.navigation-bar-v2--navy .navigation-bar-v2__link,
.navigation-bar-v2--navy .navigation-bar-v2__sub-menu-btn {
  color: white;
}
.navigation-bar-v2--navy .navigation-bar-v2__menu-btn {
  color: white;
}
@media (max-width: 74.937rem) {
  .navigation-bar-v2 .navigation-bar-v2__link,
  .navigation-bar-v2 .navigation-bar-v2__sub-menu-btn {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .navigation-bar-v2 .navigation-bar-v2__link::after,
  .navigation-bar-v2 .navigation-bar-v2__sub-menu-btn::after {
    display: none;
  }
}
@media (min-width: 75rem) {
  .navigation-bar-v2__content {
    margin: 0 auto;
    max-width: 75rem;
  }
  .navigation-bar-v2__logo-container {
    display: none;
  }
  .navigation-bar-v2__logo-link--mobile {
    display: none;
  }
  .navigation-bar-v2__menu-btn {
    display: none;
  }
  .navigation-bar-v2 .navigation-bar-v2__link,
  .navigation-bar-v2 .navigation-bar-v2__sub-menu-btn {
    border-radius: 0.5rem;
    color: #232554;
  }
  .navigation-bar-v2__sub-menu-btn-icon {
    margin-right: 0;
    width: 0.875rem;
  }
  .navigation-bar-v2__menu {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5.5rem;
    justify-content: space-between;
    margin: 0;
  }
  .navigation-bar-v2__menu-item {
    border-bottom: none;
    padding: 0;
  }
  .navigation-bar-v2__menu-item--logo {
    display: block;
  }
  .navigation-bar-v2__sub-menu {
    background-color: white;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
    display: none;
    left: 0;
    margin-top: 0;
    position: absolute;
    top: 5.3rem;
    width: 100%;
  }
  .navigation-bar-v2__sub-menu--open {
    display: block;
  }
  .navigation-bar-v2__sub-menu--open::before {
    content: "";
    height: 1rem;
    left: 0;
    position: absolute;
    top: -0.9rem;
    width: 100%;
  }
  .navigation-bar-v2__sub-menu__content {
    flex-direction: row;
    gap: 2.5rem;
    margin: 0 auto;
    max-width: 75rem;
    padding: 2rem 1.25rem 4rem;
    width: 100%;
  }
  .navigation-bar-v2--navy {
    background-color: #232554;
  }
  .navigation-bar-v2--navy .navigation-bar-v2__link,
  .navigation-bar-v2--navy .navigation-bar-v2__sub-menu-btn {
    color: white;
  }
  .navigation-bar-v2--navy .navigation-bar-v2__sub-menu {
    background-color: #232554;
  }
}
