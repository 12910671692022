.dark .label-row {
  color: #f2f2f7;
}
.dark .label-row:hover {
  background-color: rgba(204, 204, 204, 0.08);
}
.dark .label-row:hover .label-row__button {
  color: #f2f2f7;
}
.dark .label-row--active {
  color: #007aff;
  background-color: rgba(204, 204, 204, 0.08);
}
.dark .label-row--active .label-row__button {
  color: #007aff;
}

.label-row {
  transition: 100ms border ease-in-out;
  border-radius: 0.5rem;
  padding: 0.375rem 0.5rem;
  justify-content: space-between;
  gap: 0.5rem;
  color: #1c1c1e;
  border: 0.125rem solid transparent;
}
.label-row--active {
  background-color: rgba(51, 51, 51, 0.08);
}
.label-row--active:hover .label-row__button {
  color: #007aff !important;
}
.label-row--active .label-row__button {
  background: transparent;
  color: #007aff;
}
.label-row--targeted {
  border: 0.125rem solid #007aff;
}
.label-row__button {
  background: transparent;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  gap: 0.75rem;
  flex: 1;
  overflow: hidden;
}
.label-row__button:hover {
  background: transparent;
}
.label-row__button__icon-container {
  flex-basis: 1.25rem;
  flex-shrink: 0;
}
.label-row__button__text {
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: 1.5rem;
}
.label-row__context-menu-container {
  visibility: hidden;
}
.label-row__context-menu-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.label-row:hover {
  background-color: rgba(51, 51, 51, 0.08);
}
.label-row:hover .label-row__context-menu-container {
  visibility: visible;
}
.label-row:hover .label-row__button {
  color: #1c1c1e;
}
