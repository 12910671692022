.plan-call-to-action {
  display: flex;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.4px;
  width: 100%;
  margin: 1.5rem 0;
}
.plan-call-to-action:disabled {
  background-color: rgba(36, 36, 87, 0.12);
  color: rgba(36, 36, 87, 0.1215686275);
}
