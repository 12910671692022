.dark .embed-maker .embed-section-header {
  border-bottom: 1px solid #444;
}
.dark .embed-maker #embed-code {
  background-color: #777;
  border-color: #aaa;
  color: white;
}

.embed-maker .close {
  margin-top: 0px;
}
.embed-maker #embed-code {
  margin-top: 15px;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  white-space: nowrap;
  word-wrap: break-word;
}
.embed-maker label {
  cursor: pointer;
  user-select: none;
  min-width: 112px;
}
.embed-maker label input {
  cursor: pointer;
}
.embed-maker .embed-code-switcher {
  margin: 5px 0px 10px 0px;
}
.embed-maker .embed-code-switcher button {
  padding: 4px 6px;
  font-size: 12px;
}
.embed-maker .embed-code-switcher button:hover {
  color: #38566f;
}
.embed-maker .embed-code-switcher .dropdown-caret {
  margin-left: 4px;
  font-family: ariel;
}
.embed-maker .embed-preview {
  margin: 0px auto;
}
.embed-maker .share-title {
  text-align: left;
  margin: 5px;
  font-size: 12px;
}
.embed-maker .embed-section-header {
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  margin-top: 10px;
  padding-bottom: 2px;
  color: #aaa;
  font-size: 12px;
}
.embed-maker .embed-section-header:first-of-type {
  margin-top: 0px;
}
.embed-maker .embed-section-body {
  margin-bottom: 20px;
}
.embed-maker .embed-section-flex {
  display: flex;
}
@media (max-width: 500px) {
  .embed-maker .embed-section-flex {
    flex-direction: column;
  }
}
.embed-maker .embed-section-flex .embed-section {
  flex: 1;
}
.embed-maker .embed-control {
  margin: 10px 0px;
}
.embed-maker .embed-inline-control {
  display: inline-block;
}
.embed-maker .embed-inline-control input[type=radio] {
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.embed-maker .embed-inline-control input[type=checkbox] {
  margin-right: 8px;
  position: relative;
  top: 1px;
}
.embed-maker .embed-inline-control span {
  display: inline-block;
  margin-right: 15px;
}
.embed-maker .embed-size {
  margin-right: 20px;
  margin-bottom: 10px;
}
@media (max-width: 500px) {
  .embed-maker .embed-size {
    width: 100%;
  }
}
.embed-maker .embed-size .embed-size-control {
  position: relative;
  display: inline-block;
}
.embed-maker .embed-size .embed-size-control input {
  margin-top: 10px;
  width: 90px;
}
.embed-maker .embed-size .embed-size-control span {
  position: absolute;
  right: 11px;
  top: 19px;
  font-size: 12px;
  color: #aaa;
}
.embed-maker .embed-label {
  margin-right: 5px;
  cursor: pointer;
}
.embed-maker .embed-label:hover {
  color: #38566f;
}
.embed-maker .embed-player-control {
  display: inline-block;
  height: 36px;
  width: 56px;
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 38px;
  text-align: center;
  cursor: pointer;
}
.embed-maker .embed-player-control svg {
  position: relative;
  top: -2px;
  vertical-align: top !important;
}
.embed-maker .embed-player-control:hover {
  color: white;
  background-color: #0f90fa;
  border-color: #0f90fa;
}
.embed-maker .embed-player-control.selected {
  color: white;
  background-color: #0f90fa;
  border-color: #0f90fa;
}
.embed-maker .share-divider {
  border-bottom: 1px solid #e5e5e5;
}
.embed-maker .share-section-slim {
  padding: 7px 16px 0px 16px;
}
.embed-maker .share-section {
  padding: 15px;
  color: #748490;
  text-align: left;
}
.embed-maker .form-control {
  display: inline-block;
  border-radius: 3px;
}
.embed-maker select {
  max-width: 120px;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding: 2px 10px;
  padding-right: 1.5em;
  margin-left: 2px;
}
.embed-maker input[type=text] {
  border-radius: 2px;
  background-color: white;
  border: 1px solid #d1d8de;
}
.embed-maker textarea {
  width: 100%;
  border-radius: 2px;
}
.embed-maker input[type=checkbox] {
  position: relative;
  width: 20px;
  box-shadow: none;
}
.embed-maker .embed-code-container:hover #embed-copy-button {
  opacity: 1;
}
.embed-maker #embed-copy-button {
  margin: 8px;
  position: absolute;
  top: 0px;
  right: 14px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: #333;
  cursor: pointer;
  background-color: #eee;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEwMjQiIHdpZHRoPSI4OTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8cGF0aCBkPSJNMTI4IDc2OGgyNTZ2NjRIMTI4di02NHogbTMyMC0zODRIMTI4djY0aDMyMHYtNjR6IG0xMjggMTkyVjQ0OEwzODQgNjQwbDE5MiAxOTJWNzA0aDMyMFY1NzZINTc2eiBtLTI4OC02NEgxMjh2NjRoMTYwdi02NHpNMTI4IDcwNGgxNjB2LTY0SDEyOHY2NHogbTU3NiA2NGg2NHYxMjhjLTEgMTgtNyAzMy0xOSA0NXMtMjcgMTgtNDUgMTlINjRjLTM1IDAtNjQtMjktNjQtNjRWMTkyYzAtMzUgMjktNjQgNjQtNjRoMTkyQzI1NiA1NyAzMTMgMCAzODQgMHMxMjggNTcgMTI4IDEyOGgxOTJjMzUgMCA2NCAyOSA2NCA2NHYzMjBoLTY0VjMyMEg2NHY1NzZoNjQwVjc2OHpNMTI4IDI1Nmg1MTJjMC0zNS0yOS02NC02NC02NGgtNjRjLTM1IDAtNjQtMjktNjQtNjRzLTI5LTY0LTY0LTY0LTY0IDI5LTY0IDY0LTI5IDY0LTY0IDY0aC02NGMtMzUgMC02NCAyOS02NCA2NHoiIC8+PC9zdmc+);
  height: 26px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  padding: 4px;
  width: 26px;
  background-position: center;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  padding: 2px 6px;
}
.embed-maker #embed-copy-button i {
  color: #333;
}
.embed-maker #embed-copy-button:hover {
  background-color: #eee;
  border: 1px solid #bbb;
}
.embed-maker .embed-custom-size {
  margin-top: 10px;
  margin-left: 32px;
}
.embed-maker .embed-field {
  display: inline;
  padding: 0px 5px;
}
.embed-maker .embed-footer {
  display: block;
  padding: 20px 5px 0px 5px;
  text-align: center;
}
.embed-maker .embed-custom-size {
  display: none;
}
.embed-maker #embed-copy-overlay {
  display: none;
  position: absolute;
  top: 0px;
  bottom: 5px;
  right: 0px;
  left: 0px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  text-align: center;
  padding-top: 30px;
  font-weight: 600;
  color: white;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.embed-maker .visits-button {
  display: inline-block;
}
