.suggestion-card--free {
  top: 10.25rem;
}
@media (min-width: 36rem) {
  .suggestion-card--free {
    top: 8.75rem;
  }
}
@media (min-width: 62rem) {
  .suggestion-card--free {
    top: 7.25rem;
  }
}
