@charset "UTF-8";
.rc-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 0;
  vertical-align: middle;
  outline: 0px;
}
.rc-checkbox:focus, .rc-checkbox:hover {
  outline: 0px;
  opacity: 1;
}

.rc-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: none;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  outline: 0px;
}
.rc-checkbox-inner:focus, .rc-checkbox-inner:hover {
  border: 1px solid rgba(255, 255, 255, 0.8);
  outline: 0px;
}

.rc-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  width: 20px;
  height: 20px;
  outline: 0px;
}
.rc-checkbox-input:focus, .rc-checkbox-input:hover {
  outline: 0px;
}

.rc-checkbox:hover .rc-checkbox-inner {
  border-color: white;
}
.rc-checkbox:hover .rc-checkbox-inner:after {
  border-color: white;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #232554;
}

/* 选中状态 */
.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #007aff;
  background: #007aff;
}

.rc-checkbox-inner:after {
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}

.rc-checkbox-checked .rc-checkbox-inner:after {
  border-color: rgb(255, 255, 255);
}
