.dark .create-label-btn {
  color: #cccccc;
}
.dark .create-label-btn__btn {
  background: transparent;
}
.dark .create-label-btn__btn:hover {
  background: rgba(204, 204, 204, 0.08);
}

.create-label-btn {
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-bottom: 0.375rem;
  padding-right: 0.625rem;
  color: #333333;
}
.create-label-btn__label {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.create-label-btn__icon {
  font-size: 1.25rem;
}
.create-label-btn__btn {
  padding: 0 0.125rem;
}
.create-label-btn__btn:hover {
  background: rgba(51, 51, 51, 0.08);
}
