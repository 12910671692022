.features-v3__container {
  gap: 3.75rem;
  padding: 3.75rem 1.25rem;
}
@media (min-width: 62rem) {
  .features-v3__container {
    gap: 11.25rem;
    margin: 0 auto;
    max-width: 62.5rem;
    padding: 6.25rem 0;
  }
}
