.footer-v3-link,
.footer-v3-btn {
  color: rgba(35, 37, 84, 0.64);
  font-size: 1.125rem;
  font-weight: 500;
}
.footer-v3-link:active, .footer-v3-link:focus, .footer-v3-link:hover,
.footer-v3-btn:active,
.footer-v3-btn:focus,
.footer-v3-btn:hover {
  color: #232554;
}
