.accordion-item {
  display: flex;
  border-bottom: 1px solid #c8c8d5;
  flex-direction: column;
  overflow: hidden;
  transition: 0.3s ease-in-out all;
}
.accordion-item:last-of-type {
  border-bottom: none;
}
.accordion-item__button {
  background-color: transparent;
  color: #232554;
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  padding: 1.25rem 0;
  text-align: left;
  width: 100%;
}
.accordion-item__button::after {
  display: none;
}
.accordion-item__button__icon {
  opacity: 0.6;
  transition: 0.3s ease-in-out transform;
}
.accordion-item__button:active, .accordion-item__button:focus, .accordion-item__button:hover {
  background-color: transparent;
}
.accordion-item__button:active .accordion-item__button__icon, .accordion-item__button:focus .accordion-item__button__icon, .accordion-item__button:hover .accordion-item__button__icon {
  opacity: 1;
}
.accordion-item__content {
  font-size: 1.125rem;
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: 0.3s ease-in-out all;
}
.accordion-item__content a {
  color: #232554;
  text-decoration: underline;
}
.accordion-item__content a:hover {
  color: #232554;
  text-decoration: underline;
}
.accordion-item__content p {
  margin: 0;
}
.accordion-item--open .accordion-item__button__icon {
  transform: rotate(180deg);
}
.accordion-item--open .accordion-item__content {
  max-height: 10rem;
  opacity: 1;
  margin-bottom: 1.875rem;
}
