@keyframes rotation-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.labels-loader {
  gap: 0.75rem;
  height: 2rem;
  padding: 0 0.625rem;
}
.labels-loader__icon-container {
  flex-basis: 1.25rem;
}
.labels-loader__icon {
  animation: rotation-right 1s infinite linear;
}
.labels-loader__text {
  line-height: 1;
}
