.dark .context-menu-container {
  color: white;
  fill: currentColor;
}
.dark .context-menu-container__popover-button {
  color: white;
  border-radius: 0.5rem;
}
.dark .context-menu-container__popover-button:hover {
  background: rgba(255, 255, 255, 0.08);
}
.dark .context-menu-container__icon {
  fill: currentColor;
}
.dark .context-menu-container__menu {
  background-color: #333333;
}
.dark .context-menu-container__menu__item .context-menu-container__menu__item__button {
  color: #c8c8d5;
}
.dark .context-menu-container__menu__item .context-menu-container__menu__item__button:hover {
  color: white;
}

.context-menu-container {
  display: flex;
  color: #333333;
  fill: currentColor;
}
.context-menu-container:hover .context-menu-container__icon {
  background-color: rgba(0, 0, 0, 0.08);
}
.context-menu-container--open {
  display: flex;
  position: relative;
}
.context-menu-container__popover-button {
  background: transparent;
  border: none;
  padding: 0;
  color: #333333;
}
.context-menu-container__popover-button:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.context-menu-container__popover-panel {
  z-index: 1;
}
.context-menu-container__menu {
  display: none;
  border-radius: 0.25rem;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
  gap: 0.5rem;
  list-style: none;
  margin: 0;
  padding: 0.875rem;
}
.context-menu-container__menu--open {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 9.5rem;
}
.context-menu-container__menu__item {
  align-items: center;
  cursor: pointer;
  display: flex;
  text-align: left;
}
.context-menu-container__menu__item .context-menu-container__menu__item__button {
  background: transparent;
  color: #748490;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  gap: 0.75rem;
  height: auto;
  justify-content: flex-start;
  line-height: 1.5;
  padding: 0;
  width: 100%;
}
.context-menu-container__menu__item .context-menu-container__menu__item__button:hover {
  background: transparent;
  color: #333333;
}
.context-menu-container__menu__item__text {
  font-weight: 400;
  margin: 0;
}
