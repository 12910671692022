.sb-textarea {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: transparent;
  border: 0.063rem solid #ced4da;
  border-radius: 0.25rem;
  color: #1c1c1e;
  font-size: 1rem;
  padding: 0.625rem;
}
.sb-textarea:focus {
  border-color: #007aff;
  outline: 0.063rem solid #007aff;
}
.sb-textarea--has-error {
  border-color: #dc3545;
}
.sb-textarea--has-error:focus {
  border-color: #dc3545;
  outline-color: #dc3545;
}

.dark .sb-textarea {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: transparent;
  border: 0.063rem solid #ced4da;
  border-radius: 0.25rem;
  color: #1c1c1e;
  font-size: 1rem;
  padding: 0.625rem;
  color: #cccccc;
}
.dark .sb-textarea:focus {
  border-color: #007aff;
  outline: 0.063rem solid #007aff;
}
.dark .sb-textarea--has-error {
  border-color: #dc3545;
}
.dark .sb-textarea--has-error:focus {
  border-color: #dc3545;
  outline-color: #dc3545;
}
.dark .sb-textarea--has-error {
  border-color: #ff4d70;
}
.dark .sb-textarea--has-error:focus {
  border-color: #ff4d70;
  outline-color: #ff4d70;
}
