// Text
$color-label-primary: #1c1c1e;

// Link
$color-link-primary: #007aff;
$color-link-secondary: $color-label-primary;

// Surface
$color-surface-primary: #f2f2f7;

// Outlines
$color-outline-secondary: #e5e5ea;

// Shadows
$shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
$shadow-lg:
  0px 4px 6px 0px rgba(0, 0, 0, 0.05),
  0px 10px 15px 0px rgba(0, 0, 0, 0.1);

// Spacing
$spacing-section: 1.5rem;
$spacing-subsection: 0.75rem;
