.testimonial-item {
  border: 0.06rem solid #c8c8d5;
  border-radius: 0.9rem;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  gap: 1.3rem;
  padding: 1.875rem;
}
.testimonial-item__content {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  line-height: 1.5em;
}
.testimonial-item__details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.75rem;
}
.testimonial-item__details__image {
  align-self: center;
  border-radius: 50%;
  object-fit: cover;
  max-height: 2.5rem;
  max-width: 2.5rem;
}
.testimonial-item__details__name {
  font-size: 0.875rem;
  letter-spacing: -0.03rem;
  margin: 0;
  font-weight: 600;
}
.testimonial-item__details__title {
  font-size: 0.875rem;
  letter-spacing: -0.03rem;
  margin: 0;
}
