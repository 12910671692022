.upsell-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background-color: #242457;
  display: flex;
  z-index: 0;
}
.upsell-modal__close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
}
.upsell-modal__content {
  max-width: 39.5rem;
  color: white;
  text-align: center;
  gap: 1.875rem;
  padding: 0 2rem;
}
.upsell-modal__content__heading h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0;
}
@media (min-width: 27.5rem) {
  .upsell-modal__content__heading h2 {
    font-size: 3rem;
  }
}
.upsell-modal__content__heading h2 span {
  display: inline-block;
  position: relative;
  padding-bottom: 0.5rem;
}
.upsell-modal__content__heading svg {
  position: absolute;
  right: 0;
  bottom: 0;
}
.upsell-modal__content__prompt {
  font-weight: 400;
  max-width: 36rem;
  margin-bottom: 0;
}
.upsell-modal__content__cancellation {
  display: block;
  padding-top: 0.625rem;
}
.upsell-modal__content__cancellation {
  opacity: 0.6;
}
.upsell-modal__content__cta-link {
  background-color: #007aff;
  color: white;
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  font-weight: 600;
  line-height: 1.2em;
  padding: 0.9rem 1.25rem;
}
.upsell-modal__content__cta-link:hover {
  color: white;
}
.upsell-modal__content__error {
  display: none;
  max-width: 22.5rem;
  aspect-ratio: 16/9;
  background-image: url("https://statics.streamable.com/static/7cijmp3c.png");
  background-size: cover;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 37.5rem) {
  .upsell-modal__content__error {
    display: flex;
  }
}
.upsell-modal__content__error > div {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}
.upsell-modal__content__error > div > span {
  padding-left: 0.5rem;
  font-size: 1rem;
}
.upsell-modal__content__dismiss {
  text-decoration: underline;
  color: white;
}
.upsell-modal__content__dismiss:hover, .upsell-modal__content__dismiss:active, .upsell-modal__content__dismiss:focus {
  color: white;
  text-decoration: underline;
}
