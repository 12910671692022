:root {
  --footer-height: 46px;
}

.nav-pills {
  margin: 0em 0.5em;
}

.navbar-toggle {
  margin: 10px;
  width: auto;
  white-space: nowrap;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.navbar-toggle svg {
  position: relative;
  top: -1px;
}

.navbar-header {
  font-size: 16px;
  vertical-align: middle;
}

.nav-pills .nav-link {
  padding: 0.5em 1.2em;
  color: rgba(0, 0, 0, 0.54);
}

.nav-pills .nav-link:hover {
  color: #0f90fa;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
  pointer-events: none;
  border-radius: 4px;
  padding: 0.5em 0.7em;
  margin: 0em 0.5em;
  background-color: #0f90fa;
  color: white;
}

.back-button {
  color: #aaa;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px;
}
.back-button svg {
  font-size: 18px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  right: -3px;
}
.back-button:hover {
  text-decoration: none;
  color: #38566f;
}

body.noscroll {
  height: 100%;
  overflow: hidden;
}

html.dark {
  background-color: #151515;
}
html.dark body {
  background-color: #151515;
}
html.dark .card {
  background-color: #232323;
}
html.dark .main-footer {
  border-color: #333;
}
html.dark .main-footer li a:hover {
  color: white;
}
html.dark .form-control[type=text],
html.dark .form-control[type=password] {
  color: white;
  background: transparent;
}
html.dark .video-creator .url-input {
  border-bottom: 1px solid #ccc;
}
html.dark .app-container {
  background-color: #151515;
}

html ::-webkit-scrollbar {
  background: #fafafa;
  width: 10px;
}
html ::-webkit-scrollbar-button {
  background: transparent;
}
html ::-webkit-scrollbar-thumb {
  background: lightgrey;
  border: 0px solid transparent;
}
html ::-webkit-scrollbar-track:hover {
  background: transparent;
}
html ::-webkit-scrollbar-thumb:active {
  background: #878787;
}
html ::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
}
html ::-webkit-scrollbar-track:active {
  background: transparent;
}
html ::-webkit-scrollbar-corner {
  background: transparent;
}
html.dark ::-webkit-scrollbar {
  background: #151515;
}
html.dark ::-webkit-scrollbar-thumb {
  background: #3e3e3e;
}
html.dark ::-webkit-scrollbar-thumb:active {
  background: #555555;
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.app-container .delinquent-bar {
  background: red;
  color: white;
  text-align: center;
  font-size: 12px;
  padding: 8px;
  height: 40px;
}
.app-container .delinquent-bar a {
  display: inline-block;
  padding: 3px 8px;
  color: white;
  background: darkred;
  margin: 0px 12px;
  border-radius: 5px;
  font-weight: 600;
}
.app-container .delinquent-bar a:hover {
  background: rgb(83, 1, 1);
}
.app-container .anon-bar {
  background: black;
  color: white;
  text-align: center;
  font-size: 0.875rem;
  padding: 1rem 2rem;
  line-height: 1.5rem;
}
.app-container .anon-bar a {
  color: white;
  font-weight: 600;
  text-decoration: underline;
}
.app-container .main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  position: relative;
}
.app-container .main-footer {
  flex: 0 0 var(--footer-height);
  text-align: center;
  border-top: 1px solid #ddd;
}
.app-container .main-footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.app-container .main-footer li {
  display: inline-block;
}
.app-container .main-footer li a {
  padding: 12px 10px;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  color: #aaa;
}
.app-container .main-footer li a:hover {
  color: black;
}
.app-container .main-footer li a b {
  color: #0f90fa;
  font-weight: 600;
}
.app-container .main-footer li a b:hover {
  color: #1a70b9;
}
.app-container .video-creator {
  border-radius: 0.625rem;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
}
.app-container .video-creator .clipper-form {
  flex: 1;
  margin-left: 15px;
  margin-right: 5px;
}
.app-container .video-creator .url-input {
  border-radius: 0px;
  border: 0;
  height: 36px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #444;
  transition: border 0.1s cubic-bezier(0.4, 0, 0.2, 1), color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
}
.app-container .video-creator .url-input:hover {
  border-color: #444;
}
.app-container .video-creator .blue-button {
  height: 36px;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  padding: 0px 18px;
  vertical-align: inherit;
  border: none;
  position: relative;
  letter-spacing: 0;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.app-container .video-creator .blue-button svg {
  font-size: 22px;
}
.app-container .video-creator__upload-button {
  background: #007aff;
}
.app-container .video-creator__upload-button:hover {
  background: #007aff;
}
.app-container .zoom-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10000;
  display: flex;
  flex-direction: column;
}
.app-container .zoom-backdrop .zoom-inner {
  padding-bottom: 160px;
  margin: 0px auto;
  width: 100%;
  position: relative;
}
.app-container .zoom-backdrop .filler {
  width: 100%;
  background: black;
}
.app-container .zoom-backdrop .player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.app-container .zoom-backdrop .poster {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.app-container .zoom-backdrop .zoom-header .close-icon {
  cursor: pointer;
  float: right;
  color: white;
  font-size: 24px;
  margin-top: 4px;
  margin-right: 12px;
}
.app-container .zoom-backdrop .zoom-header .close-icon svg {
  vertical-align: baseline;
}
.app-container .zoom-backdrop .zoom-footer {
  color: #aaa;
  font-size: 12px;
  padding: 3px 0px 6px 0px;
}
.app-container .zoom-backdrop .zoom-title {
  font-size: 18px;
  color: white;
  width: 100%;
  text-align: left;
  line-height: 1.4em;
}
.app-container .zoom-backdrop .zoom-content {
  min-height: min-content;
  min-height: -moz-min-content;
  min-height: -webkit-min-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 10px;
}
.app-container .zoom-backdrop .zoom-content video {
  border-radius: 3px;
}

@media (min-width: 576px) {
  .main-footer {
    margin: 0px 140px;
  }
}
.delinquent-bar .mobile-only {
  display: none;
}

@media (max-width: 700px) {
  .delinquent-bar span {
    display: none;
  }
  .mobile-only {
    display: inline !important;
  }
}
