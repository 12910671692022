.clipper {
  gap: 2rem;
  padding: 2rem 0 0;
  position: relative;
  text-align: center;
}
.clipper .clipper__title {
  font-size: 2.5rem;
  line-height: 1em;
  font-weight: 800;
  letter-spacing: -1px;
}
.clipper__copy {
  font-size: 1.125rem;
  letter-spacing: -0.5px;
  line-height: 1.8rem;
}
.clipper__copy > p {
  margin-bottom: 0;
}
.clipper .input-container {
  width: 100%;
  max-width: 38.75rem;
}
.clipper .input-container svg {
  margin-left: 1.5rem;
  position: absolute;
}
.clipper .input-container form,
.clipper .input-container input {
  width: 100%;
}
.clipper .input-container input {
  border: 1px solid #ced4da;
  min-width: 17.5rem;
  padding: 1.5rem;
  padding-left: 3.75rem;
  border-radius: 0.625rem;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0509803922);
}
.clipper .input-container input:focus {
  border-color: #007aff;
}
.clipper .input-container input::placeholder {
  font-size: 1.25rem;
}
@media (min-width: 48rem) {
  .clipper {
    padding: 3.125rem 2.5rem;
  }
  .clipper .clipper__title {
    font-size: 3.5rem;
  }
}
@media (min-width: 62rem) {
  .clipper__copy {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}
@media (min-width: 75rem) {
  .clipper .clipper__title {
    font-size: 5rem;
    line-height: 6.125rem;
  }
}
