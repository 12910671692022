.payment-collection-skeleton-wrapper {
  gap: 1rem;
  padding: 1rem 0;
}
.payment-collection-skeleton-wrapper .payment-collection-skeleton {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.payment-collection-skeleton-wrapper .payment-collection-skeleton__submit-button {
  padding: 0.5rem;
}
.payment-collection-skeleton-wrapper .payment-collection-skeleton__card-details {
  display: flex;
  gap: 1rem;
}
