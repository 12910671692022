@import '../../../../common/scss/variables.scss';

.video-notifications-container {
  position: absolute;
  left: 0.75rem;
  bottom: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  & .video-notification-tooltip-content {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    margin: 0;

    &__upgrade-btn {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.video-notification {
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;

  border-radius: 0.125rem;
  display: flex;
  gap: 0.25rem;
  flex-direction: row;
  align-items: center;
  padding: 0 0.25rem;
  height: 1.5rem;

  & .icon {
    font-size: 0.75rem;
  }

  &--danger {
    background: rgba(255, 0, 0, 0.7);
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
  }

  &--warn {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
