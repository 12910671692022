:root {
  --sidebar-width: 17.5rem;
}

.labels-sidebar-container {
  display: none;
  position: relative;
}
.labels-sidebar-container--slide-over {
  display: flex;
}
.labels-sidebar-container--slide-over .labels-sidebar {
  overflow: hidden;
}
@media (min-width: 62rem) {
  .labels-sidebar-container--slide-over {
    display: none !important;
  }
}
@media (min-width: 62rem) {
  .labels-sidebar-container--inline {
    display: block;
  }
}

.dark .labels-sidebar-container--slide-over .labels-sidebar {
  background-color: rgb(35, 35, 35);
}
.dark .labels-sidebar-container--inline .labels-sidebar {
  background-color: #151515;
}

.labels-sidebar-container .labels-sidebar {
  padding: 1.25rem;
  z-index: 2;
  background: #f1f1f1;
}
.labels-sidebar-container .labels-sidebar__labels-list {
  height: 100%;
  list-style: none;
  margin: 0;
  overflow: hidden auto;
  padding: 0;
}
.labels-sidebar-container .labels-sidebar.labels-sidebar--fixed {
  position: fixed;
  max-width: var(--sidebar-width);
  width: 19%;
  top: 0;
}

.labels-sidebar-container--slide-over {
  display: flex;
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  left: 0;
}
.labels-sidebar-container--slide-over .labels-sidebar__overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.labels-sidebar-container--slide-over .labels-sidebar {
  position: relative;
  background-color: white;
  height: 100%;
  width: var(--sidebar-width);
  padding: 1rem;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.labels-sidebar-container--slide-over.labels-sidebar-container--close {
  left: -100%;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.labels-sidebar-container--slide-over.labels-sidebar-container--close .labels-sidebar__overlay {
  display: none;
}
.labels-sidebar-container--slide-over.labels-sidebar-container--close .labels-sidebar {
  width: 0;
  opacity: 0;
  visibility: hidden;
}

.labels-sidebar-container--inline {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  width: var(--sidebar-width);
}
@media (min-width: 62rem) {
  .labels-sidebar-container--inline .labels-sidebar {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
.labels-sidebar-container--inline.labels-sidebar-container--close {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  width: 0;
}

.labels-sidebar__static-labels-container {
  margin-bottom: 1.125rem;
}
