.sb-tooltip {
  display: inline-block;
  position: relative;
}
.sb-tooltip__trigger {
  background: transparent;
  border: none;
  display: flex;
  padding: 0;
}
.sb-tooltip__trigger:hover {
  background: transparent;
}
.sb-tooltip__panel {
  border-radius: 0.25rem;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 0.25rem 0.5rem;
  min-width: 18rem;
}
