.pricing-page {
  padding-top: 1.25rem;
}
.pricing-page__title {
  margin: 0;
  text-align: center;
}
.pricing-page__description {
  font-size: 1.5rem;
  margin: 1.25rem auto;
  padding: 0 1.25rem;
  text-align: center;
}
@media (min-width: 48rem) {
  .pricing-page {
    padding: 3.125rem 0 0;
  }
  .pricing-page__title {
    font-size: 3.375rem;
  }
  .pricing-page__description {
    max-width: 100%;
  }
}
