.card .video-footer {
  background-color: #f9f9f9;
  border-top: 0.063rem solid rgba(0, 0, 0, 0.05);
  border-radius: 0 0 0.547rem 0.547rem;
  line-height: 1.5;
  padding: 0.25rem;
}
.card .video-footer button {
  border: none;
  background: none;
  color: #333333;
  flex: 1;
  font-weight: 400;
  padding: 0 1.625rem;
}
@media (max-width: 1200px) {
  .card .video-footer button {
    padding: 0 1rem;
  }
  .card .video-footer button .extra {
    display: none;
  }
}
.card .video-footer button svg {
  margin-right: 0.375rem;
}
.card .video-footer button:hover {
  background: none;
  color: #38566f;
}
.card .video-footer .dropdown {
  border-right: none;
}
.card .video-footer .dropdown .material-symbols-outlined {
  position: absolute;
  font-size: 20px;
}
.card .video-footer .rightmost {
  border-right: none;
}
.card .video-footer__actions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.card .video-footer__actions-container .action-button {
  border-right: 0.063rem solid rgba(0, 0, 0, 0.05);
  flex: 1 1;
  margin-left: auto;
  min-width: fit-content;
}
.card .video-footer__actions-container .action-button:only-child {
  max-width: fit-content;
}
.card .video-footer__actions-container .action-button:not(:only-child) {
  padding-left: 0;
  padding-right: 0;
}
.card .video-footer__actions-container .action-button:last-child {
  border-right: none;
}
.card .video-footer__actions-container .action-button.dropdown > button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.card .video-footer__disabled {
  opacity: 0.38;
  pointer-events: none;
  cursor: not-allowed;
}

.dark .card .video-footer {
  background-color: #333333 !important;
  border-color: #444444 !important;
}
.dark .card .video-footer .action-button {
  border-color: #444444 !important;
}
