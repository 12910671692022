.sales-led-plan-section {
  padding: 1.25rem;
  text-align: center;
}
.sales-led-plan-section__content {
  background-color: #232554;
  border-radius: 1.5rem;
  color: white;
  margin: 0 auto;
  max-width: 62.5rem;
  padding: 3.75rem 2.5rem;
}
.sales-led-plan-section .sales-led-plan-section__content__title {
  color: white;
  margin-bottom: 0.5rem;
}
.sales-led-plan-section .sales-led-plan-section__content__text {
  font-size: 1.25rem;
  margin-top: 0.625rem;
}
.sales-led-plan-section .sales-led-plan-section__content__cta {
  border-radius: 0.625rem;
  font-size: 1.125rem;
  margin-top: 2.625rem;
}
@media (min-width: 48rem) {
  .sales-led-plan-section {
    padding: 6.25rem 1.25rem 0;
  }
  .sales-led-plan-section__content {
    padding: 6.25rem;
  }
}
@media (min-width: 75rem) {
  .sales-led-plan-section {
    padding-left: 0;
    padding-right: 0;
  }
  .sales-led-plan-section__content__text {
    font-size: 1.625rem;
  }
}
