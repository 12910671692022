.dark .selectable-option {
  color: white;
  border: 1px solid #878787;
}
.dark .selectable-option__radio {
  background-color: transparent;
  border: 1px solid #878787;
}
.dark .selectable-option:hover {
  border-color: #f3f3f3;
}
.dark .selectable-option--selected {
  border-color: #f3f3f3;
}
.dark .selectable-option--selected .selectable-option__radio {
  border-color: #007aff;
}
.dark .selectable-option--selected .selectable-option__radio:after {
  background: #007aff;
}

.selectable-option {
  align-items: center;
  background: none;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  color: #333333;
  display: flex;
  font-size: 0.875rem;
  margin: 0;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  user-select: none;
}
.selectable-option__radio {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 1.125rem;
  position: relative;
  width: 1.125rem;
}
.selectable-option--slim {
  border: none !important;
  padding: 0;
}
.selectable-option--disabled .selectable-option__radio {
  opacity: 0.38;
}
.selectable-option:hover {
  cursor: pointer;
  border-color: #333333;
}
.selectable-option--selected {
  border-color: #333333;
}
.selectable-option--selected .selectable-option__radio {
  border-color: #007aff;
}
.selectable-option--selected .selectable-option__radio:after {
  background: #007aff;
  border-radius: 50%;
  content: "";
  height: 0.75rem;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 0.75rem;
}
.selectable-option__label-container {
  flex-grow: 1;
}
