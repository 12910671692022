.text-with-highlight-stroke {
  position: relative;
}
.text-with-highlight-stroke__underline {
  bottom: 0;
  color: #63abff;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 2px) scale(1);
}
