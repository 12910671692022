.navigation-bar-sub-menu-section {
  flex: 1 1;
  gap: 1.25rem;
}
.navigation-bar-sub-menu-section__title {
  font-size: 1.125rem;
  font-weight: 800;
}
.navigation-bar-sub-menu-section__list {
  gap: 1.25rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigation-bar-sub-menu-section__link {
  color: rgba(35, 37, 84, 0.64);
  font-size: 1.125rem;
  font-weight: 600;
}
.navigation-bar-sub-menu-section__link:active, .navigation-bar-sub-menu-section__link:focus, .navigation-bar-sub-menu-section__link:hover {
  color: #232554;
}

.navigation-bar-v2--navy .navigation-bar-sub-menu-section__title {
  color: white;
}
.navigation-bar-v2--navy .navigation-bar-sub-menu-section__link {
  color: rgba(255, 255, 255, 0.64);
}
.navigation-bar-v2--navy .navigation-bar-sub-menu-section__link:active, .navigation-bar-v2--navy .navigation-bar-sub-menu-section__link:focus, .navigation-bar-v2--navy .navigation-bar-sub-menu-section__link:hover {
  color: white;
}
