.player {
  text-align: center;
  line-height: 0px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  color: white;
  position: relative;
  outline: 0;
}
.player video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.player.activity .player-actions {
  opacity: 1;
  transition: none;
}
.player .progress-image {
  width: 100%;
}
.player #progress-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.player #progress-overlay .progress-spinner img {
  height: 30px;
  margin-bottom: 20px;
}
.player #progress-overlay #progress-bar-track {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.9);
  max-width: 120px;
  width: 100%;
  margin: 0px 20px;
}
.player #progress-overlay #progress-bar-value {
  background-color: rgba(255, 255, 255, 0.9);
  height: 26px;
  position: relative;
  width: 0;
  -webkit-transition: width 1s linear;
  -moz-transition: width 1s linear;
  transition: width 1s linear;
}
.player #progress-overlay .error-indicator {
  display: none;
}
.player #progress-overlay .error-indicator svg {
  font-size: 30px;
  color: white;
  margin-bottom: 10px;
}
.player .stm-ad-player {
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 999999;
}
.player video {
  vertical-align: middle;
}
.player #spinner,
.player #spinner:before,
.player #spinner:after {
  background: #fff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.player #spinner:before,
.player #spinner:after {
  position: absolute;
  top: 0;
  content: "";
}
.player #spinner:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.player #spinner {
  display: none;
  opacity: 0.8;
  text-indent: -9999em;
  margin: 8em auto;
  font-size: 4px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  top: -10px;
  right: 22px;
  z-index: 4;
}
.player #spinner:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0 #fff;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em #ffffff;
    height: 4em;
  }
}
@keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0 #fff;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em #ffffff;
    height: 4em;
  }
}
.player #player-overlay-mute-button {
  padding: 5px;
  display: inline-block;
  text-align: left;
}
.player #player-overlay-mute-button .overlay-volume-mute {
  display: none;
  position: relative;
  top: 1px;
  left: -3px;
}
.player #player-overlay-mute-button .overlay-volume-up {
  position: relative;
  top: 1px;
  left: 4px;
}
.player #player-overlay-mute-button.muted .overlay-volume-up {
  display: none;
}
.player #player-overlay-mute-button.muted .overlay-volume-mute {
  display: block;
}
.player .player-group {
  text-align: left;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  opacity: 0.9;
}
.player .player-group .group-toggle {
  display: inline-block;
  cursor: pointer;
  padding: 16px;
}
.player .player-group .video-title {
  padding: 16px;
}
.player .player-group svg, .player .player-group > span {
  vertical-align: middle;
}
.player .player-group svg {
  position: relative;
  top: -1px;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
}
.player .player-group > span {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}
.player .player-group:hover {
  opacity: 1;
}
.player .player-group .group-count {
  margin: 0px 10px;
}
.player .player-group-sidebar {
  text-align: left;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  display: none;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  width: 100%;
  max-width: 240px;
  overflow-y: auto;
  font-weight: 400;
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 0;
}
.player .player-group-sidebar .group-title {
  padding: 5px 8px;
  opacity: 0.9;
}
.player .player-group-sidebar .player-group-sidebar-item {
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.player .player-group-sidebar .player-group-sidebar-item img {
  height: 50px;
  width: 80px;
  object-fit: cover;
  padding: 0px 5px;
}
.player .player-group-sidebar .player-group-sidebar-item span {
  opacity: 0.7;
  display: inline-block;
}
.player .player-group-sidebar .player-group-sidebar-item .title {
  font-size: 14px;
}
.player .player-group-sidebar .player-group-sidebar-item:hover, .player .player-group-sidebar .player-group-sidebar-item.playing {
  background-color: rgba(255, 255, 255, 0.1);
}
.player .player-group-sidebar .player-group-sidebar-item:hover span, .player .player-group-sidebar .player-group-sidebar-item.playing span {
  opacity: 1;
}
.player .twitter-follow-button {
  float: right;
  margin: 12px 15px;
  color: white;
  font-size: 12px;
  opacity: 0.9;
  position: relative;
  font-family: "Open Sans", Helvetica, sans-serif;
  text-decoration: none;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}
.player .twitter-follow-button:hover {
  opacity: 1;
  text-decoration: none;
}
.player .twitter-follow-button svg {
  position: absolute;
  left: -28px;
  top: -4px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
}
.player .player-controls {
  user-select: none;
  transition: opacity 0.2s linear;
  opacity: 0;
  position: absolute;
  text-align: left;
  line-height: 1.4em;
}
.player #player-controls-header {
  height: 40px;
  right: 0;
  top: 0;
  left: 0;
}
.player #player-controls-footer {
  height: 98px;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-position: bottom;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
}
.player #player-controls-footer * {
  user-select: none;
  pointer-events: auto;
}
.player #player-controls-footer #player-settings-overlay {
  display: none;
  position: absolute;
  right: 15px;
  bottom: 44px;
  width: 120px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  font-family: "Open Sans", Helvetica, sans-serif;
  padding-bottom: 10px;
  line-height: 17.16px;
}
.player #player-controls-footer #player-settings-quality-header {
  border-bottom: 1px solid #444;
  font-weight: 600;
  padding: 8px 10px;
  opacity: 0.9;
  text-align: center;
  line-height: 17.16px;
}
.player #player-controls-footer .player-settings-quality-option {
  font-weight: 600;
  cursor: pointer;
  opacity: 0.9;
  line-height: 17.16px;
  padding: 7px 10px 0px 10px;
}
.player #player-controls-footer .player-settings-quality-option svg {
  visibility: hidden;
  position: relative;
  top: 4px;
  margin-right: 10px;
}
.player #player-controls-footer .player-settings-quality-option:hover {
  color: white;
  opacity: 1;
}
.player #player-controls-footer .player-settings-quality-option.checked svg {
  visibility: visible;
}
.player #player-controls-footer #player-settings-button {
  position: absolute;
  bottom: 13px;
  right: 50px;
  line-height: 0;
  opacity: 0.9;
  cursor: pointer;
}
.player #player-controls-footer #player-settings-button svg {
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
}
.player #player-controls-footer #player-settings-button:hover {
  opacity: 1;
}
.player #player-controls-footer #player-progress {
  position: absolute;
  right: 12px;
  left: 12px;
  cursor: pointer;
  bottom: 40px;
  padding: 10px 0px;
}
.player #player-controls-footer #player-progress div {
  height: 4px;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.player #player-controls-footer #player-progress #player-progress-hover {
  line-height: 0;
  opacity: 0;
  position: absolute;
  top: -10px;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
  width: 100px;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 14px;
  transition: opacity 0.15s ease;
  font-weight: 600;
}
.player #player-controls-footer #player-progress #player-progress-track {
  position: absolute;
  left: 0px;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  opacity: 0.3;
}
.player #player-controls-footer #player-progress #player-progress-value {
  position: absolute;
  left: 0px;
  border-radius: 2px 0px 0px 2px;
  width: 0px;
  opacity: 0.7;
}
.player #player-controls-footer #player-progress #player-progress-value-hover {
  position: absolute;
  left: 0px;
  border-radius: 2px 0px 0px 2px;
  width: 0px;
  transition: opacity 0.3s;
  opacity: 0.4;
}
.player #player-controls-footer #player-progress:hover #player-progress-value,
.player #player-controls-footer #player-progress:hover #player-progress-track,
.player #player-controls-footer #player-progress:hover #player-progress-value-hover {
  transform: scaleY(1.4);
}
.player #player-controls-footer #player-progress:hover #player-progress-value {
  background-color: white;
}
.player #player-controls-footer #player-fullscreen-button {
  position: absolute;
  bottom: 8px;
  right: 12px;
  cursor: pointer;
  opacity: 0.9;
}
.player #player-controls-footer #player-fullscreen-button svg {
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
}
.player #player-controls-footer #player-fullscreen-button:hover {
  opacity: 1;
}
.player #player-controls-footer #player-fullscreen-button svg.compact {
  display: none;
}
.player #player-controls-footer #player-play-pause {
  bottom: -7px;
  position: absolute;
  left: 3px;
  line-height: 0px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
  opacity: 0.9;
  cursor: pointer;
  /* Important stuff above, everything below is just to normalize and/or show off. */
}
.player #player-controls-footer #player-play-pause:hover {
  opacity: 1;
}
.player #player-controls-footer #player-play-pause span::before {
  box-sizing: border-box;
  height: 18px;
  width: 0;
  border: 0 solid transparent;
  border-left: inherit;
  transition: border 0.1s linear;
}
.player #player-controls-footer #player-play-pause input:checked + span::before {
  border-width: 9px 0 9px 18px;
  border-style: solid;
}
.player #player-controls-footer #player-play-pause label {
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.player #player-controls-footer #player-play-pause input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.player #player-controls-footer #player-play-pause span {
  font-size: 0;
}
.player #player-controls-footer #player-play-pause span::before,
.player #player-controls-footer #player-play-pause span::after {
  font-size: 1rem;
}
.player #player-controls-footer #player-play-pause span::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 18px;
  margin: -9px 0 0 -9px;
}
.player #player-controls-footer #player-volume {
  position: absolute;
  height: 40px;
  width: 140px;
  left: 32px;
  bottom: 4px;
  cursor: pointer;
  line-height: 0;
}
.player #player-controls-footer #player-volume #player-volume-control {
  bottom: -2px;
  position: absolute;
  left: 45px;
  transition: opacity 0.1s;
  opacity: 0;
}
.player #player-controls-footer #player-volume #player-volume-track {
  opacity: 0.5;
  bottom: 0px;
  position: absolute;
  left: 0px;
}
.player #player-controls-footer #player-volume #player-volume-value {
  bottom: 0px;
  position: absolute;
  left: 0px;
}
.player #player-controls-footer #player-volume svg {
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
  opacity: 0.9;
}
.player #player-controls-footer #player-volume:hover svg {
  opacity: 1;
}
.player #player-controls-footer #player-volume:hover #player-volume-control {
  opacity: 1;
}
.player #player-controls-footer #player-mute-button {
  bottom: 4px;
  position: absolute;
  left: 13px;
  line-height: 0px;
}
.player #player-controls-footer #player-mute-button svg.volume-down {
  display: none;
}
.player #player-controls-footer #player-mute-button svg.volume-mute {
  display: none;
}
.player #player-controls-footer #player-mute-button.low {
  left: 9px;
}
.player #player-controls-footer #player-mute-button.low svg.volume-down {
  display: block;
}
.player #player-controls-footer #player-mute-button.low svg.volume-up {
  display: none;
}
.player #player-controls-footer #player-mute-button.low svg.volume-mute {
  display: none;
}
.player #player-controls-footer #player-mute-button.muted {
  left: 6px;
}
.player #player-controls-footer #player-mute-button.muted svg.volume-down {
  display: none;
}
.player #player-controls-footer #player-mute-button.muted svg.volume-up {
  display: none;
}
.player #player-controls-footer #player-mute-button.muted svg.volume-mute {
  display: block;
}
.player .watermark {
  max-height: 120px;
  max-width: 120px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  transition: bottom 0.2s linear, opacity 0.2s linear;
  z-index: 2000;
  opacity: 0.7;
  pointer-events: none;
}
.player .watermark img {
  max-height: 40px;
  max-width: 120px;
  object-fit: contain;
}

.player.fullscreen {
  background-color: black;
  width: 100%;
  position: relative;
  max-width: 100% !important;
  max-height: 100% !important;
}
.player.fullscreen #player-fullscreen-button svg.compact {
  display: block !important;
}
.player.fullscreen #player-fullscreen-button svg.expand {
  display: none;
}
.player.fullscreen video {
  height: 100% !important;
  background-color: black;
  object-fit: contain;
}

.player.hide-audio #player-volume {
  display: none !important;
}

.mobile-player .watermark {
  bottom: inherit;
  top: 10px;
  max-height: 60px;
  max-width: 60px;
}
.mobile-player .watermark img {
  max-height: 60px;
  max-width: 60px;
}

.player.user-activity .player-controls {
  transition: none;
  opacity: 1;
}
.player.user-activity .watermark {
  transition: none;
  bottom: 50px !important;
  opacity: 1;
}

.player.controls .player-controls {
  display: block;
}

#context-menu {
  z-index: 999999;
  display: none;
  position: absolute;
  width: 200px;
  font-family: Helvetica, sans-serif;
  text-align: left;
  border-radius: 3px;
  overflow: hidden;
  opacity: 0.9;
  line-height: 1.4em;
}
#context-menu .context-menu-item {
  text-decoration: none;
  display: block;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 4px 10px;
  color: #ccc;
}
#context-menu .context-menu-item:first-of-type {
  padding-top: 6px;
}
#context-menu .context-menu-item {
  font-size: 12px;
}
#context-menu .context-menu-item:hover {
  color: white;
}
#context-menu .context-menu-footer {
  font-size: 10px !important;
  color: #888;
  text-align: center;
  padding: 6px;
  background-color: #222;
}
#context-menu .context-menu-footer img {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -1px;
}
#context-menu #speed {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#context-menu #speed:hover {
  color: #ccc;
}
#context-menu #speed .speed-button {
  float: right;
  padding: 0px 5px;
}
#context-menu #speed .speed-button:hover {
  color: white;
}
#context-menu #speed .active {
  font-weight: 600;
  color: white;
}

#adBackdrop {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  visibility: hidden;
}

#embedContainer {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  top: 30%;
  margin-top: -50px;
  position: absolute;
  width: 100%;
}

#embedContainer p {
  color: white;
  font-weight: 500;
  font-size: 32px;
  margin: 10px;
}

#embedContainer .button {
  background-color: blue;
  background: #44abfa;
  background-image: -webkit-linear-gradient(top, #44abfa, #1a81d6);
  background-image: -moz-linear-gradient(top, #44abfa, #1a81d6);
  background-image: -ms-linear-gradient(top, #44abfa, #1a81d6);
  background-image: -o-linear-gradient(top, #44abfa, #1a81d6);
  background-image: linear-gradient(to bottom, #44abfa, #1a81d6);
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
}

#embedContainer a {
  text-decoration: none;
}

#embedContainer .button:hover {
  background: #1684de;
  text-decoration: none;
}

#copy-embed-button {
  display: inline-block;
  position: absolute;
  line-height: 45px;
  margin-left: -90px;
  width: 90px;
  -webkit-border-radius: 0 4 4 0;
  -moz-border-radius: 0 4 4 0;
  border-radius: 0px 4px 4px 0px;
}

#embedContainer #clip-on-streamable {
  font-size: 18px;
  font-weight: 400;
}
#embedContainer #clip-on-streamable a {
  color: #008dfe;
  font-weight: 600;
}
#embedContainer #clip-on-streamable a:hover {
  color: #00aaff;
}

#clip-on-streamable-button {
  margin-top: 5px;
  width: 230px;
  margin-right: auto;
  margin-left: auto;
  font-weight: 300;
}

#embed-overlay-x-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px;
  opacity: 0.9;
}
#embed-overlay-x-button svg {
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5)); /* Same syntax as box-shadow */
}
#embed-overlay-x-button:hover {
  opacity: 1;
}

#embed-code-field {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  height: 45px;
  border: 0px;
  width: 70%;
  font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
  color: #898f8f;
  font-weight: 400;
  cursor: text;
  margin: 0;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 4px;
  font-size: 16px;
}

#embed-overlay {
  text-align: right;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  display: none;
}

#adContainer {
  background-color: black;
  position: absolute;
  margin: auto;
  visibility: hidden;
  left: 0px;
  top: 0px;
  -webkit-box-shadow: 0px 0px 0px 100px rgb(0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 100px rgb(0, 0, 0);
  box-shadow: 0px 0px 0px 100px rgb(0, 0, 0);
  z-index: 100;
}
#adContainer #adClose {
  position: absolute;
  top: -36px;
  left: 0px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  text-shadow: 0px 0px 10px black;
  pointer-events: none;
}
#adContainer #adRemaining {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: absolute;
  bottom: -22px;
  left: 0px;
  right: 0px;
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-shadow: 0px 0px 10px black;
  pointer-events: none;
  height: 18px;
}
#adContainer > div {
  height: 100%;
  width: 100%;
}
#adContainer > div > iframe {
  height: 100%;
  width: 100%;
}
