.upsell-label .upsell-label__container {
  align-items: center;
  color: #007aff;
  display: inline-flex;
  flex-direction: row;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  opacity: 1 !important;
  padding: 0 0.5rem;
  text-transform: uppercase;
}
.upsell-label .upsell-label__container__icon {
  color: #ffe352;
}

.dark .upsell-label .upsell-label__container {
  color: #007aff;
}
