.left-panel {
  max-width: 20rem;
  color: #1c1c1e;
  font-size: 0.875rem;
}
.left-panel__heading-container {
  gap: 1rem;
  padding: 2rem 0 1.5rem 0;
}
.left-panel__trial-copy {
  font-size: 1.25rem;
  font-weight: 600;
}
.left-panel__title {
  font-size: 2rem;
  font-weight: 600;
}
.left-panel__plan-details-toggle {
  font-weight: 600;
  font-size: 0.875rem;
  display: none;
}
.left-panel__plan-details-toggle:hover, .left-panel__plan-details-toggle:focus, .left-panel__plan-details-toggle:active {
  color: #007aff;
}
.left-panel__features-container {
  display: none;
}
.left-panel__features-container--desktop {
  display: block;
}
.left-panel__features-container--hidden {
  display: none !important;
}
@media screen and (max-width: 48rem) {
  .left-panel__features-container--mobile {
    display: block;
  }
  .left-panel__features-container--desktop {
    display: none;
  }
}
.left-panel__features {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  padding: 1.5rem 0;
}
.left-panel__features__item {
  font-size: 0.875rem;
}
.left-panel__features__item__title {
  font-weight: 700;
  margin-bottom: 0.375rem;
}
.left-panel__compare-container {
  font-size: 0.875rem;
}
.left-panel__compare-container a {
  color: #1c1c1e;
  text-decoration: underline;
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-weight: 600;
  gap: 0.5rem;
  text-decoration: underline;
}
.left-panel__compare-container a:hover {
  color: #007aff;
  text-decoration: underline;
}
.left-panel__compare-container__right-arrow {
  transform: rotate(180deg);
}
@media (max-width: 48rem) {
  .left-panel {
    max-width: 27rem;
    gap: 1.5rem;
  }
  .left-panel__heading-container {
    align-items: center;
    padding: 0;
  }
  .left-panel__plan-details-toggle {
    display: block;
  }
}
