.dark .signin-page .form-control[type=text], .dark .signin-page .form-control[type=password] {
  color: black;
}
.dark .signin-page .forgot-password {
  color: #cccccc;
}
.dark .signin-page .forgot-password:hover {
  color: white;
}

.signin-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.signin-page * {
  font-family: "Manrope", sans-serif;
}
.signin-page .footer {
  font-size: 0.875rem;
  margin-top: 1.25rem;
  height: 0;
}
.signin-page .footer div {
  margin-bottom: 10px;
}
.signin-page .footer a {
  color: #555;
  font-weight: 600;
}
.signin-page .footer a:hover {
  color: black;
}
.signin-page .footer .question {
  margin-right: 4px;
}
.signin-page .row {
  height: 100%;
}
.signin-page .signin-box {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background: white !important;
  border: 1px solid #e5e5ea;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
  text-align: center;
  width: 340px;
}
.signin-page .signin-box .logo {
  margin-bottom: 5px;
}
.signin-page .signin-box h1 {
  color: #333333;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 8px;
}
.signin-page .signin-box p:not(:last-child) {
  color: #555;
  margin-bottom: 20px;
}
.signin-page .nav-tabs {
  position: relative;
  padding-left: 102px;
  z-index: 1;
  border-bottom: none;
}
.signin-page .nav-tabs a {
  border-bottom: none;
}
.signin-page .nav-tabs a:hover {
  color: #38566f;
  border-bottom: none;
}
.signin-page .nav-tabs .nav-link {
  color: rgb(116, 132, 144);
  padding: 0.7em 1.2em;
  border: none;
}
.signin-page .nav-tabs .nav-link.active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.signin-page .card {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background: white !important;
  border: 1px solid #e5e5ea;
  border-radius: 1rem;
  z-index: 2;
}
.signin-page .forgot-password {
  color: rgb(116, 132, 144);
}
.signin-page .forgot-password:hover {
  color: #38566f;
  text-decoration: none;
}

.recover {
  padding: 20px;
  margin: 30px auto !important;
  max-width: 400px;
}
.recover button {
  width: 100%;
}
.recover h1 {
  color: #333333;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}
.recover__error-message {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.signin-widget {
  gap: 1.5rem;
}
.signin-widget #my-signin2 {
  display: flex;
  justify-content: center;
  height: 38px;
}
.signin-widget__social-buttons {
  gap: 0.75rem;
}
.signin-widget__input {
  padding: 0.45rem 0.75rem;
  width: 100%;
}
.signin-widget__submit-btn {
  border-radius: 0.25rem;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  width: 100%;
}
.signin-widget__form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.signin-widget__form-group {
  position: relative;
}
.signin-widget__form-group .form-control {
  padding: 10px 14px;
}
.signin-widget__form-group .form-control-emoji {
  position: absolute;
  top: 2px;
  right: 6px;
  font-size: 20px;
}
.signin-widget__terms {
  font-size: 0.875rem;
  margin-bottom: 0;
}
.signin-widget .signin-alert {
  text-align: left;
  margin-bottom: 15px;
  clear: both;
  font-weight: 600;
  margin-left: 2px;
}
.signin-widget .signin-alert.error {
  color: red;
}
.signin-widget .facebook-button {
  background: white;
  border: 1px solid #dadce0;
  border-radius: 4px;
  color: #3c4043;
  cursor: pointer;
  vertical-align: top;
  width: 280px;
  line-height: 0px;
  padding: 0.5rem 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.signin-widget .facebook-button:hover {
  border-color: #d2e3fc;
  background: rgba(66, 133, 244, 0.04);
}
.signin-widget .facebook-button svg {
  height: 1.25rem;
  width: 1.25rem;
}
.signin-widget .facebook-button .facebook-text {
  border: none;
  flex: 1;
  font-family: sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}
.signin-widget .separator {
  background-color: #cccccc;
  height: 1px;
  text-align: center;
  position: relative;
  margin: 0.5rem 0;
}
.signin-widget .separator span {
  position: relative;
  top: -0.875rem;
  background-color: white;
  display: inline-block;
  color: #888;
  text-align: center;
  padding: 0px 15px;
  font-size: 0.875rem;
}

.user-authentication {
  color: #1c1c1e;
  max-width: 280px;
  gap: 1.5rem;
}
.user-authentication__section {
  gap: 0.75rem;
}
.user-authentication__switcher {
  font-size: 0.875rem;
}
.user-authentication__switcher .sb-button--variant-link {
  color: #007aff;
  text-decoration: none;
}
.user-authentication__switcher .sb-button--variant-link:hover {
  color: #007aff;
  text-decoration: underline;
}
.user-authentication .signin-widget {
  width: 100%;
}
.user-authentication__agreement-copy {
  color: #6b6d70;
  font-size: 0.75rem;
}
.user-authentication__agreement-copy a {
  color: #6b6d70;
  text-decoration: underline;
}
.user-authentication__agreement-copy a:hover {
  color: #232323;
  text-decoration: underline;
}
