.suggested-plan {
  border: 1px solid #dddddd;
  border-radius: 0.5rem;
  gap: 0.75rem;
  padding: 1rem;
}
.suggested-plan__title-container {
  gap: 0.5rem;
}
.suggested-plan .plan-title {
  color: #333333;
  font-weight: 600;
  height: auto;
  margin-top: 0;
}
.suggested-plan .plan-description {
  font-size: 0.875rem;
}
.suggested-plan .plan-price-container,
.suggested-plan .plan-description,
.suggested-plan .plan-features-list {
  margin-top: 0;
}
.suggested-plan .plan-call-to-action {
  border-radius: 0.25rem;
  font-size: 1rem;
  height: 2.25rem;
  margin-top: 0.75rem;
  letter-spacing: 0.1px;
}
.suggested-plan .pricing-link {
  font-size: 0.875rem;
  letter-spacing: -0.4px;
  text-align: center;
  text-decoration: none;
}

.dark .suggested-plan .plan-title {
  color: white;
}
