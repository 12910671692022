.dark .replace-video-dropzone--dragging {
  background: #3b4455;
}

.replace-video-dropzone {
  min-height: 15rem;
  border: 1px dashed #ced4da;
  margin: 1.875rem 0;
  border-radius: 0.25rem;
  gap: 0.75rem;
}
.replace-video-dropzone--dragging {
  border: 1px dashed #007aff;
  background: #f1f6fe;
}
.replace-video-dropzone--error {
  border: 1px dashed #dc3545;
}
.replace-video-dropzone__error {
  color: #dc3545;
  font-size: 1rem;
  line-height: 1.875rem;
  text-align: center;
  margin: 0.5rem 0;
  width: 80%;
}
.replace-video-dropzone__icon {
  height: 3rem;
  margin-bottom: 0.5rem;
  opacity: 0.64;
}
.replace-video-dropzone__button {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
}
.replace-video-dropzone__note {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: center;
  opacity: 0.64;
  margin-bottom: 0.5rem;
}
.replace-video-dropzone__progress-container {
  height: 11.25rem;
  width: 80%;
}
.replace-video-dropzone__progress-container .progress-overlay {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
}
