.sb-toggle {
  background: none;
  border: none;
  border-radius: 0.875rem;
  box-shadow: none;
  padding: 0;
}
.sb-toggle__visible-toggle {
  background-color: #bbbbbb;
  border-radius: inherit;
  height: 100%;
  padding: 0.125rem;
  position: relative;
  width: 100%;
}
.sb-toggle__visible-toggle::after {
  background-color: white;
  border-radius: 50%;
  content: "";
  display: block;
  transition: transform 0.2s ease-in-out;
}
.sb-toggle[aria-checked=true] .sb-toggle__visible-toggle {
  background-color: #007aff;
}
.sb-toggle:disabled {
  opacity: 0.38;
}
.sb-toggle:disabled .sb-toggle__visible-toggle {
  background-color: #bbbbbb;
}
.sb-toggle--small {
  height: 1.25rem;
  width: 2.5rem;
}
.sb-toggle--small .sb-toggle__visible-toggle::after {
  height: 1rem;
  width: 1rem;
}
.sb-toggle--small[aria-checked=true] .sb-toggle__visible-toggle::after {
  transform: translateX(1.25rem);
}
.sb-toggle--large {
  height: 1.75rem;
  width: 3.75rem;
}
.sb-toggle--large .sb-toggle__visible-toggle::after {
  height: 1.5rem;
  width: 1.5rem;
}
.sb-toggle--large[aria-checked=true] .sb-toggle__visible-toggle::after {
  transform: translateX(2rem);
}

.dark .sb-toggle__visible-toggle {
  background-color: #444444;
}
.dark .sb-toggle:disabled .sb-toggle__visible-toggle {
  background-color: #444444;
}
