.user-menu {
  position: absolute;
  right: 16px;
  top: 8px;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  z-index: 90;
  min-width: 200px;
  max-width: 300px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  user-select: none;
}
.user-menu .border-top {
  border-top: 1px solid #eee;
}
.user-menu .user-menu-separator {
  font-size: 11px;
  padding: 8px 10px 3px 10px;
  font-weight: 600;
}
.user-menu .toggle {
  float: right;
  margin: 0px auto;
  cursor: pointer;
  height: 20px;
  width: 40px;
  background: #bbb;
  border-radius: 10px;
  position: relative;
  top: 1px;
}
.user-menu .toggle .toggle-knob {
  background: white;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 2px;
  top: 2px;
}
.user-menu .toggle.selected {
  background: #007aff;
}
.user-menu .toggle.selected .toggle-knob {
  right: 2px;
  left: inherit;
}
.user-menu .user-menu-header {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #333;
  text-align: right;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
  font-weight: 600;
  padding: 3px 0;
  border-radius: 5px;
  position: relative;
  padding-right: 28px;
  cursor: pointer;
}
@media (max-width: 36rem) {
  .user-menu .user-menu-header {
    padding-right: 0px;
  }
}
.user-menu .user-menu-header svg {
  font-size: 20px;
  position: absolute;
  margin-left: 3px;
  top: 5px;
  right: 8px;
}
