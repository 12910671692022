.privacy-and-settings {
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 0.625rem;
  font-size: 1rem;
  padding: 1.875rem;
}
.privacy-and-settings--slim {
  padding: 1.875rem 1.5rem;
}
.privacy-and-settings__intro {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1.875rem;
}
.privacy-and-settings .alert {
  margin-top: 1rem;
}
.privacy-and-settings .alert button {
  padding: 0;
}
.privacy-and-settings .alert .alert-link {
  color: #007aff;
  font-weight: unset;
}
.privacy-and-settings .selectable-option {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
  font-size: 1rem;
  padding: 1rem 0 1rem 0.75rem;
}
.privacy-and-settings .selectable-option:hover {
  border-color: #e6e6e6;
}
.privacy-and-settings .selectable-option__label-container {
  align-items: flex-start;
  flex-direction: column;
}
.privacy-and-settings .selectable-option--disabled .with-upgrade__label,
.privacy-and-settings .selectable-option--disabled .sb-text--secondary {
  opacity: 0.64;
}
.privacy-and-settings__restrictions__text-field-container {
  background-color: #f3f3f3;
  border-radius: 0.25rem;
  padding: 1.25rem;
}
.privacy-and-settings__restrictions__text-field-container label {
  color: #333333;
  font-weight: 600;
}
.privacy-and-settings__restrictions__text-field-container input {
  background-color: white;
}
.privacy-and-settings__advanced .privacy-and-settings__advanced__cta {
  color: #dc3545;
  flex-shrink: 0;
  font-weight: 400;
}
@media (max-width: 36rem) {
  .privacy-and-settings__advanced .settings-option {
    align-items: flex-start;
    flex-direction: column;
  }
  .privacy-and-settings__advanced .privacy-and-settings__advanced__cta {
    margin: 1.375rem 0;
  }
}
.privacy-and-settings .settings-option .sb-toggle {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.dark .privacy-and-settings {
  background-color: #232323;
  border-color: #444444;
}
.dark .privacy-and-settings__intro {
  border-color: #444444;
}
.dark .privacy-and-settings .selectable-option {
  border-color: #444444;
}
.dark .privacy-and-settings .selectable-option:hover {
  border-color: #444444;
}
.dark .privacy-and-settings__restrictions__text-field-container {
  background-color: #333333;
}
.dark .privacy-and-settings__restrictions__text-field-container .sb-text-field label {
  color: #cccccc;
}
.dark .privacy-and-settings__restrictions__text-field-container .sb-text-field input {
  background-color: transparent;
  border-color: #878787;
}
