.due-today {
  font-weight: 700;
  gap: 0.5rem;
}
.due-today__totals {
  font-weight: 400;
}
.due-today__label {
  gap: 0.25rem;
}
.due-today__free-trial {
  color: #34c759;
}
@media screen and (max-width: 36rem) {
  .due-today {
    font-size: 0.875rem;
  }
}
