.card-block .video-labels {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0.188rem;
  color: #6b6d70;
  display: grid;
  font-size: 0.75rem;
  font-weight: 400;
  gap: 0.3rem;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  height: 2rem;
  line-height: 1;
  max-width: fit-content;
  padding: 0 0.25rem;
}
.card-block .video-labels__icon {
  font-size: 0.75rem;
}
.card-block .video-labels__none {
  margin-left: 0.15rem;
}
.card-block .video-labels__list {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr;
}
.card-block .video-labels__list__item {
  display: -webkit-box;
  line-height: 0.9rem;
  margin: 0 0.15rem;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card-block .video-labels__list__item:last-child {
  margin-right: 0;
}
.card-block .video-labels:hover {
  background: #f3f3f3;
  border: none;
  color: #6b6d70;
}

.rc-tooltip-placement-top {
  padding-bottom: 5px;
}

.dark .video-labels {
  color: #cccccc;
}
.dark .video-labels:hover {
  background: #333333;
}
