.sb-switch {
  background-color: #f3f3f3;
  border-radius: 3rem;
  padding: 0.25rem;
}
.sb-switch__label {
  background-color: transparent;
  border-radius: 3rem;
  color: #007aff;
  margin: 0;
  padding: 0.25rem 0.9rem;
}
.sb-switch__label--checked {
  background-color: white;
  color: #232554;
}
.sb-switch__label:hover {
  cursor: pointer;
}
.sb-switch__input {
  display: none;
}
