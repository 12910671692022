.download-files-modal {
  display: flex;
  margin: initial;
  width: 100%;
  height: calc(100vh - 4rem);
  justify-content: center;
  align-items: center;
}
.download-files-modal.modal-dialog {
  max-width: 37.5rem !important;
}
.download-files-modal .modal-content {
  width: 100%;
  border-radius: 1rem;
}
.download-files-modal .modal-header {
  padding: 1.5rem;
  align-items: center;
  display: flex;
}
.download-files-modal .modal-header .close {
  color: #1c1c1e;
  font-size: 1rem;
  font-weight: 600;
  opacity: 0.75;
  margin-right: -0.5rem;
  padding: 0.5rem;
}
.download-files-modal .modal-header .close:hover {
  opacity: 1;
  color: black;
}
.download-files-modal .modal-header .modal-title {
  color: #1c1c1e;
  font-family: "Open Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}
.download-files-modal .modal-body {
  padding: 1.5rem;
  padding-top: 0;
}
.download-files-modal .modal-body .file-list {
  display: flex;
  flex-direction: column;
}
.download-files-modal .modal-body .file-list .file-list-item {
  display: flex;
  padding: 1rem 0rem;
  justify-content: space-between;
  gap: 1rem;
}
.download-files-modal .modal-body .file-list .file-list-item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
.download-files-modal .modal-body .file-list .file-list-item .name-container {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 1rem;
}
.download-files-modal .modal-body .file-list .file-list-item .name-container .name {
  font-weight: 600;
}
.download-files-modal .modal-body .file-list .file-list-item .name-container .description {
  color: #333;
  opacity: 0.64;
}
.download-files-modal .modal-body .file-list .file-list-item .name-container .upgrade-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  border: 0;
  background: 0;
  padding: 0;
  color: #007aff;
}
.download-files-modal .modal-body .file-list .file-list-item .name-container .upgrade-link span.icon {
  color: #ffe352;
}
.download-files-modal .modal-body .file-list .file-list-item .buttons-container {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}
.download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button {
  color: #1c1c1e;
  display: flex;
  height: 2.25rem;
  padding: 0rem 0.75rem 0rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  background: transparent;
}
.download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button:disabled {
  opacity: 0.5;
}
.download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button.copied {
  background-color: #059e05;
  color: white;
}
.download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button .copy span {
  display: block;
  line-height: 0;
  white-space: nowrap;
}
.download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button .copy span.hidden {
  visibility: hidden;
}
@media (max-width: 36rem) {
  .download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button .icon {
    position: relative;
    left: 2px;
  }
  .download-files-modal .modal-body .file-list .file-list-item .buttons-container .icon-button .button-text {
    display: none;
  }
}
.download-files-modal .modal-body .alert-info {
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.download-files-modal .modal-body .alert-info button {
  border: 0;
  background: 0;
  font-weight: 600;
  color: #0c5460;
}
.download-files-modal .modal-body .alert-info button:hover {
  color: #1c1c1e;
}

.dark .download-files-modal .modal-content {
  background-color: #232323;
}
.dark .download-files-modal .modal-content .modal-header .modal-title {
  color: #ccc;
}
.dark .download-files-modal .modal-content .modal-header .close {
  color: #ccc;
  text-shadow: none;
}
.dark .download-files-modal .modal-content .modal-body .file-list .file-list-item:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
.dark .download-files-modal .modal-content .modal-body .file-list .file-list-item .name-container .description {
  color: #ccc;
  opacity: 0.64;
}
.dark .download-files-modal .modal-content .modal-body .file-list .file-list-item .icon-button {
  color: #ccc;
  border-color: #878787;
}
.dark .download-files-modal .modal-content .modal-body .file-list .file-list-item .icon-button.copied {
  color: white;
  border-color: white;
}
