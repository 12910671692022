@import '../../../common/scss/variables.scss';

.ticker-with-dropzone-hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $spacing-large;
  padding: 3.125rem $spacing-small 0;
  position: relative;
  text-align: center;

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    position: relative;
    z-index: 1;
  }

  &__title {
    line-height: 1.2em;
  }

  &__line-expression {
    color: $color-green-light;
    display: none;
  }

  &__text-line-expression {
    display: none;
    color: $color-orange-200;
    position: absolute;
    right: -2.75rem;
    top: -3.25rem;
  }

  &__text {
    font-size: $font-size-heading-4;
    letter-spacing: -0.5px;
    margin: 0;

    a {
      color: $color-blue-navy;
      text-decoration: underline;

      &:hover {
        color: $color-blue-navy;
        text-decoration: underline;
      }
    }

    &--upload {
      font-size: $font-size-text-medium;
    }
  }

  &__ticker-terms-wrapper {
    position: relative;
    width: 100%;
  }

  &__ticker-container {
    position: relative;
    width: 100%;
  }

  &__terms {
    margin-top: $spacing-small;
    font-size: $font-size-text-small;
    margin-left: $spacing-2xl;
    margin-right: $spacing-2xl;
  }

  @media (min-width: $breaking-point-md) {
    gap: $spacing-2xl;
    padding: $spacing-xl 0 0;

    &__info {
      .ticker-with-dropzone-hero {
        &__text {
          margin-left: auto;
          margin-right: auto;
          max-width: 40rem;
        }
      }
    }

    &__line-expression {
      display: block;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-190%);
      z-index: 0;
    }

    &__text-line-expression {
      display: block;
    }

    &__text {
      &--upload {
        font-size: $font-size-text-large;
      }
    }
  }

  @media (min-width: $breaking-point-xl) {
    &__text {
      font-size: $font-size-heading-4;

      &--upload {
        font-size: $font-size-text-large;
      }
    }
  }
}
