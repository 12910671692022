.sign-up-cta {
  gap: 2rem;
  padding: 2rem 0 0;
  position: relative;
  text-align: center;
}
.sign-up-cta__title {
  font-size: 2.5rem;
  line-height: 1em;
  font-weight: 800;
  letter-spacing: -1px;
}
.sign-up-cta__link {
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  gap: 0.625rem;
  font-weight: 600;
  line-height: 1.2em;
  padding: 0.9rem 1.25rem;
  background: #007aff;
  border: none;
  color: white;
  position: relative;
}
.sign-up-cta__link::after {
  border-radius: inherit;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.sign-up-cta__link::after {
  background-color: white;
}
.sign-up-cta__link:active, .sign-up-cta__link:focus, .sign-up-cta__link:hover {
  background: #007aff;
  color: white;
}
.sign-up-cta__link:active::after, .sign-up-cta__link:focus::after, .sign-up-cta__link:hover::after {
  opacity: 0.08;
}
.sign-up-cta__link[disabled] {
  background: #e6e6e6;
  color: rgba(51, 51, 51, 0.38);
  pointer-events: none;
}
.sign-up-cta__copy {
  font-size: 1.125rem;
  letter-spacing: -0.5px;
  line-height: 2rem;
}
.sign-up-cta__copy > p {
  margin-bottom: 0;
}
@media (min-width: 48rem) {
  .sign-up-cta {
    padding: 3.125rem 2.5rem;
  }
  .sign-up-cta__title {
    font-size: 3.5rem;
  }
  .sign-up-cta__link {
    border-radius: 0.75rem;
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
  }
}
@media (min-width: 75rem) {
  .sign-up-cta__title {
    font-size: 5rem;
    line-height: 6.125rem;
  }
}
@media (min-width: 100rem) {
  .sign-up-cta {
    gap: 2rem;
    padding: 3.125rem 2.5rem;
  }
}
