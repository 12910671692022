.testimonials_v2 .testimonials {
  margin: 0 auto;
  margin-top: 6.875rem;
  margin-bottom: 6.875rem;
  max-width: 62.5rem;
}
.testimonials_v2 .testimonials__title {
  font-size: 3.75rem;
  line-height: 4.125rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  text-align: center;
}
.testimonials_v2 .testimonials__container {
  width: calc(100% - 2.5rem);
  margin: 0 auto;
  margin-top: 5rem;
}
.testimonials_v2 .testimonials__content {
  padding: 0;
}
.testimonials_v2 .testimonials h2 {
  width: 85%;
  margin: 0 auto;
}
