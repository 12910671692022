.plan-price-container {
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.plan-price-container__row {
  flex-direction: row;
}
.plan-price-container__price {
  font-size: 1.875rem;
  font-weight: 700;
}
.plan-price-container__price--original {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  opacity: 0.38;
  padding-right: 0.25rem;
}
.plan-price-container__frequency {
  font-size: 0.875rem;
  font-weight: 400;
}
.plan-price-container__secondary-label {
  font-size: 0.875rem;
  font-weight: 500;
}
