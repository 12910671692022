.email-verification-required {
  gap: 0.938rem;
}
.email-verification-required__copy {
  color: #333333;
  font-size: 0.875rem;
}
.email-verification-required__cta {
  border-radius: 0.188rem;
  font-size: 0.875rem;
  height: 2.25rem;
}
