@import '../../../common/scss/variables.scss';
@import '../../../common/scss/loop/variables.scss';
@import '../../../common/scss/loop/mixins.scss';

.upgrade-modal {
  * {
    font-family: 'Manrope', sans-serif;
  }

  .sb-modal__panel {
    min-height: 100vh;
    max-width: none;
    border-radius: 0;
    background-color: $color-surface-primary;

    &__content {
      max-height: calc(100vh - 3.75rem);
      padding-left: 0;
      padding-right: 0;
    }

    &__header__close-button {
      background: transparent;
      color: $color-grey-800;
    }
  }

  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  &__cadence-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 0;
  }

  &__cadence {
    background: #e5e5ea;
    border-radius: 0.75rem;

    &__savings {
      margin-left: 0.25rem;
      background: linear-gradient(120deg, #007aff 13.4%, #9543ff 86.6%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .sb-switch {
      background-color: transparent;

      &__label {
        color: $color-label-primary;
        border-radius: 0.5rem;
        font-size: $font-size-text-medium;
        font-weight: 500;

        &--checked {
          box-shadow: 0px 2px 4px 0px #0000000f;
        }
      }
    }
  }

  &__title {
    font-weight: 800;
    text-align: center;
    font-size: 3rem;
    color: $color-label-primary;
    padding-top: 1.5rem;
  }

  &__enterprise-footer {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
    max-width: 70rem;
    margin: 2rem auto 0 auto;
    flex-direction: column;

    &__text {
      font-size: 1rem;
      color: $color-label-primary;

      strong {
        font-weight: $font-weight-semibold;
      }

      a:hover {
        color: #007bff;
        text-decoration: underline;
      }
    }
  }

  &__terms {
    max-width: 70rem;
    padding: 0 1rem;
    margin: 2rem auto;
    font-size: $font-size-text-default;
  }

  &__plans-carousel {
    overflow-x: auto;
    display: flex;
    padding: 0 1rem;
    scroll-snap-type: x mandatory;
  }

  &__plans-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.25rem 0;
    min-width: 70rem;
  }
}

.upgrade-modal-card {
  flex: 1;
  scroll-snap-align: center;
  width: 16.5625rem;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 6px 0px #0000000d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: $color-label-primary;

  &--borderless {
    border: none;
    box-shadow: none;
  }

  &__popular-wrapper,
  &__padding {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  &__popular-wrapper {
    padding: 1px;
    background: linear-gradient(120deg, #007aff 13.4%, #9543ff 86.6%);
    border-radius: 0.75rem;
    box-shadow: 0px 4px 6px 0px #0000000d;

    &__text {
      text-align: center;
      font-size: 1rem;
      color: white;
      font-weight: 500;
      padding: 0.5rem 0;
    }
  }

  &__padding {
    padding-top: 2.625rem;

    // interim fix until we standardize these cards
    .pricing-plan {
      @include card-slim;

      // override default radius to adjust for nested border radiuses
      border-radius: 0.75rem;
      margin-top: 0;
    }
  }

  .plan-title {
    height: auto;
    margin-bottom: 0;
  }
}

@media (min-width: 1180px) {
  .upgrade-modal__container {
    width: calc(100vw - 4rem);
  }

  .upgrade-modal__title {
    padding-top: 4rem;
  }

  .upgrade-modal__plans-carousel {
    justify-content: center;
    padding: 0;
  }

  .upgrade-modal__enterprise-footer {
    padding: 0;
    flex-direction: row;
    width: 70rem;
  }
}
