.video-captions-delete-modal .sb-modal__panel {
  max-width: 26rem;
  margin: 1rem;
}
.video-captions-delete-modal .sb-modal__panel__header {
  padding-bottom: 0.625rem;
}
.video-captions-delete-modal .delete-error-container {
  color: #dc3545;
  margin-top: 1rem;
}
