.pricing-plan {
  border: 0.063rem solid #c8c8d5;
  border-radius: 0.75rem;
  padding: 1.5rem;
  flex: 1;
}
.pricing-plan p {
  margin-bottom: 0;
}
@media (min-width: 62rem) {
  .pricing-plan {
    border: none;
  }
}
.pricing-plan .plan-call-to-action {
  font-size: 1rem;
  padding: 0.5rem;
}
.pricing-plan .plan-call-to-action:disabled {
  background-color: rgba(36, 36, 87, 0.12);
  color: rgba(36, 36, 87, 0.1215686275);
}
