.hero-dropzone {
  align-items: center;
  background-color: white;
  border: 0.06rem solid #c8c8d5;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  height: 22.5rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 40rem;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out transform;
  width: 100%;
  z-index: 2;
}
.hero-dropzone:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.hero-dropzone__info__title {
  font-size: 1.5rem;
  margin: 0;
}
.hero-dropzone__info__text {
  font-size: 1.125rem;
  margin: 0.625rem 0 0;
}
.hero-dropzone__cta {
  border-radius: 0.625rem;
}
.hero-dropzone__no-credit-card {
  font-size: 1.125rem;
  margin: 0;
  opacity: 0.6;
}
.hero-dropzone__overlay {
  align-items: center;
  background-color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.hero-dropzone__overlay__text {
  font-size: 1.5rem;
  font-weight: 500;
}
