.links {
  margin: 3rem 0;
}
.links .links-header {
  font-size: 3.75rem;
  line-height: 4.125rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 0 1.25rem;
}
.links .links-container {
  gap: 0.75rem;
  margin: 3rem;
  width: 100%;
  max-width: 62.5rem;
  flex-wrap: wrap;
}
.links .links-container .link {
  color: #242457;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  padding: 0.625rem 1.25rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: center;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0509803922);
}
.links .links-container .link-skeleton {
  max-width: 6rem;
  border-radius: 0.5rem;
}
@media (max-width: 48rem) {
  .links .links-container {
    width: 90%;
  }
}
