.feature-item {
  display: flex;
  flex-direction: column-reverse;
  gap: 2.5rem;
}
.feature-item__details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature-item__details__title {
  margin: 0;
}
.feature-item__details__text {
  font-size: 1.125rem;
  margin: 0;
}
.feature-item__media-container {
  border-radius: 1.5rem;
  text-align: center;
}
.feature-item__media {
  border-radius: 1.5rem;
  max-width: 100%;
}
@media (min-width: 48rem) {
  .feature-item {
    align-items: center;
    flex-direction: row;
    gap: 5rem;
  }
  .feature-item--left {
    flex-direction: row-reverse;
  }
  .feature-item > * {
    flex: 1 1;
  }
  .feature-item__details__title {
    margin: 0;
  }
}
