.billing-details {
  gap: 1rem;
}
.billing-details__plan-details {
  gap: 0.5rem;
  padding: 0.375rem 0;
}
.billing-details__plan-details__logo-mark {
  color: black;
}
.billing-details__plan-details__plan-name {
  color: #333333;
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
}
.billing-details__plan-container {
  gap: 1rem;
}
.billing-details__title {
  margin-top: 0.75rem;
}
.billing-details__error-message {
  background-color: #f3d8da;
  border-radius: 0.25rem;
  color: #692326;
  margin-top: 1rem;
  padding: 0.5rem 0.75rem;
}
.billing-details .StripeElement {
  border: 0.063rem solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.625rem;
}
.billing-details__selectable-cadence__discount {
  background: #fff8d4;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  padding: 0.25rem;
}
.billing-details__cta {
  border-radius: 0.188rem;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 1rem;
  padding: 0.5rem 0;
  width: 100%;
}
.billing-details__due-today-container {
  color: #333333;
  font-size: 1.25rem;
  font-weight: 600;
  gap: 0.5rem;
  margin-top: 1.875rem;
}
.billing-details__due-today-container__price {
  flex-grow: 1;
  text-align: right;
}
.billing-details__due-today-container__price--trial {
  color: #999999;
  font-weight: 400;
  text-decoration: line-through;
}
.billing-details__trial-copy {
  color: #333333;
  font-size: 0.875rem;
  margin-top: 1rem;
}
.billing-details__agreement-copy {
  align-self: flex-start;
  color: #6b6d70;
  font-size: 0.75rem;
}
.billing-details__agreement-copy a {
  color: #6b6d70;
  text-decoration: underline;
}
.billing-details__agreement-copy a:hover {
  color: #232323;
  text-decoration: underline;
}
